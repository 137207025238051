import { defineStore } from 'pinia'

const INITIAL_STATE = {
  isHelpOpen: false,
  recentActivity: '',
}

const useHelpStore = defineStore('help', {
  state: () => ({ ...INITIAL_STATE }),
  getters: {},
  actions: {
    setRecentActivity(activity: string) {
      this.recentActivity = activity
    },
    toggleHelpModal(to?: boolean) {
      if (typeof to === 'undefined') {
        this.isHelpOpen = !this.isHelpOpen

        return
      }

      this.isHelpOpen = to
    },
  },
  persist: {
    storage: window.localStorage,
  },
})

export default useHelpStore
