import { defineStore } from 'pinia'

const useSupportStore = defineStore('support', {
  state: () => ({
    // add initial state here
  }),
  actions: {
    // add actions here
  },
})

export default useSupportStore
