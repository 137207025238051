<template>
  <main class="overflow-x-hidden">
    <TheHeader v-if="!isMobile" />

    <slot />

    <component :is="footerComponent" />
  </main>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import TheHeader from '../components/layout/Header/TheHeader.vue'
import { useDevicePlatform } from '../composables'
import TheBottomNavigation from '@/modules/Core/components/layout/BottomNavigation/TheBottomNavigation.vue'
import TheFooter from '@/modules/Core/components/layout/Footer/TheFooter.vue'

defineOptions({
  name: 'TheErrorLayout',
})

const { isMobile } = useDevicePlatform()

// FOOTER
const footerComponent = computed(() =>
  isMobile.value ? TheBottomNavigation : TheFooter
)
</script>
