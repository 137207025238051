import { type UseMutationOptions, useMutation } from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import type { SubscribeUserSuccessResponse } from '@/modules/Core/types/api/response/subscribeUser'
import type { SubscribeUserPayload } from '@/modules/Core/types/api/payload/subscribeUser'
import { axiosInstance } from '@/modules/Core/plugins/axios'

const ENDPOINT = '/User/Fcm/Subscribe'

export function subscribeUser(payload: SubscribeUserPayload) {
  return axiosInstance.post<SubscribeUserSuccessResponse>(ENDPOINT, payload)
}

export function useSubscribeUserMutation(
  options?: UseMutationOptions<
    AxiosResponse<SubscribeUserSuccessResponse>,
    AxiosCustomError,
    SubscribeUserPayload,
    any
  >
) {
  return useMutation({
    mutationFn: subscribeUser,
    ...options,
  })
}
