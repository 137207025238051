<template>
  <div class="px-16 py-20 md:px-0">
    <JButton
      v-if="selectedHelpItem && isDesktop"
      icon="alt-arrow-left-line"
      size="sm"
      variant="outlined"
      class="mb-8 rounded-2xl !border-line !text-gray-600 hover:!bg-line focus:!bg-line focus:!outline-line"
      type="button"
      @click="clearSelectedHelpItem"
    >
      {{ $t('words.back') }}
    </JButton>

    <JTextInput
      v-model="helpSearch"
      class="mb-16"
      type="search"
      :placeholder="$t('words.search')"
      iconClass="text-primary"
    />

    <HelpSkeleton v-if="isLoading" />

    <div
      v-else-if="
        !isLoading &&
        (!!helpItems.length ||
          !!foundHelpItem?.length ||
          !!Object.keys(filteredHelpItems).length)
      "
    >
      <!-- FETCHED HELP ITEMS -->
      <div
        v-if="helpItems.length && !selectedHelpItem && !helpSearch.length"
        class="flex flex-col gap-12"
      >
        <template v-if="helpItems[recentHelpItemIndex]">
          <span class="text-subject font-bold">{{
            $t('help.basedOnYourRecentActivity')
          }}</span>
          <HelpItem
            v-if="helpItems[recentHelpItemIndex]"
            key="recentHelpItem"
            :title="helpItems[recentHelpItemIndex][0]"
            @select="selectedHelpItem = $event"
          />
          <hr class="text-line" />
        </template>
        <HelpItem
          v-for="([title], index) in remainingHelpItems"
          :key="index"
          :title="title"
          @select="selectedHelpItem = $event"
        />
      </div>

      <!-- SELECTED HELP ITEM BY CLICK -->
      <template
        v-else-if="
          helpItems.length &&
          selectedHelpItem &&
          foundHelpItem &&
          !helpSearch.length
        "
      >
        <SelectedHelpItem :title="foundHelpItem[0]" :item="foundHelpItem[1]" />
      </template>

      <!-- FILTERED HELP ITEMS BY SEARCH -->
      <template v-else-if="!Array.isArray(filteredHelpItems)">
        <FilteredHelpItems
          v-for="([title, items], index) in Object.entries(sortedHelpItems)"
          :key="index"
          :title="title"
          :items="items"
        />
      </template>
    </div>

    <!-- NOT FOUND -->
    <div
      v-if="
        !isLoading &&
        (!helpItems.length || !Object.keys(filteredHelpItems).length)
      "
      class="py-40"
    >
      <NotFound />
    </div>
  </div>
</template>

<script setup lang="ts">
import { JButton, JTextInput } from '@journey/ui-kit'
import { computed, onBeforeUnmount, ref } from 'vue'
import { useHelpQuery } from '@/modules/Help/requests/queries'
import HelpSkeleton from '@/modules/Help/components/HelpSkeleton/HelpSkeleton.vue'
import HelpItem from '@/modules/Help/components/HelpItem/HelpItem.vue'
import SelectedHelpItem from '@/modules/Help/components/SelectedHelpItem/SelectedHelpItem.vue'
import FilteredHelpItems from '@/modules/Help/components/FilteredHelpItems/FilteredHelpItems.vue'
import NotFound from '@/modules/Core/components/partials/NotFound/NotFound.vue'
import type { HelpItemContent } from '@/modules/Help/types/model/helpItem'
import { useDevicePlatform } from '@/modules/Core/composables'
import useHelpStore from '@/modules/Help/store'

const { isDesktop } = useDevicePlatform()

const helpSearch = ref('')

const { data, isLoading } = useHelpQuery()
const helpStore = useHelpStore()

const helpItems = computed(() => Object.entries(data.value?.data || []))
const recentHelpItemIndex = computed(() =>
  helpItems.value.findIndex((i) => i[0] === helpStore.recentActivity)
)
const remainingHelpItems = computed(() => {
  if (recentHelpItemIndex.value === -1) {
    return helpItems.value
  }

  return [
    ...helpItems.value.slice(0, recentHelpItemIndex.value),
    ...helpItems.value.slice(recentHelpItemIndex.value + 1),
  ]
})

const selectedHelpItem = ref('')

function clearSelectedHelpItem() {
  selectedHelpItem.value = ''
  helpSearch.value = ''
}

const foundHelpItem = computed(() =>
  helpItems.value.find((i) => i[0] === selectedHelpItem.value)
)

const flattenedHelpItems = computed(() => {
  const result: { [key in string]: Array<HelpItemContent> } = {}

  if (foundHelpItem.value) {
    Object.entries(foundHelpItem.value[1]).forEach(([k, v]) => {
      result[k] = v
    })

    return result
  }

  helpItems.value.forEach(([_, value]) => {
    Object.entries(value).forEach(([k, v]) => {
      result[k] = v
    })
  })

  return result
})

const filteredHelpItems = computed(() => {
  const searchValue = helpSearch.value.toLowerCase()

  const filtered = Object.entries(flattenedHelpItems.value).filter(
    ([key, items]) =>
      key.toLowerCase().includes(searchValue) ||
      items.some(
        (item) =>
          item.helpText.toLowerCase().includes(searchValue) ||
          item.screenSection.includes(searchValue)
      )
  )

  return Object.fromEntries(filtered)
})

const sortedHelpItems = computed(() => {
  const searchValue = helpSearch.value.toLowerCase()

  const sorted = Object.entries(filteredHelpItems.value).sort((a, b) => {
    if (a[0].toLowerCase().includes(searchValue)) {
      return -1
    }

    if (b[0].toLowerCase().includes(searchValue)) {
      return 1
    }

    return 0
  })

  return Object.fromEntries(sorted)
})

onBeforeUnmount(() => {
  clearSelectedHelpItem()
})

defineExpose({
  selectedHelpItem,
  clearSelectedHelpItem,
})
</script>
