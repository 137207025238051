import { defineStore } from 'pinia'
import { nanoid } from 'nanoid/non-secure'
import type { Optional } from 'utility-types'
import type { BGColors } from '@journey/ui-kit'

interface Toast {
  id: string
  title: string
  color: BGColors
  duration: number
}

interface State {
  toasts: Toast[]
}

const DEFAULT_TOAST_ERROR_TIMEOUT = 6000

const useToastStore = defineStore('toast', {
  state: (): State => ({
    toasts: [],
  }),
  actions: {
    addToast(toast: Optional<Omit<Toast, 'id'>, 'duration'>) {
      const id = nanoid()
      const newToast = {
        ...toast,
        id,
        duration: toast.duration || DEFAULT_TOAST_ERROR_TIMEOUT,
      }

      this.toasts.push(newToast)

      setTimeout(() => {
        this.removeToast(id)
      }, newToast.duration)
    },
    removeToast(id: Toast['id']) {
      this.toasts = this.toasts.filter((toast) => toast.id !== id)
    },
  },
})

export default useToastStore
