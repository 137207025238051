import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { UAParser } from 'ua-parser-js'

export default function useDevicePlatform() {
  const isMobile = ref(false)
  const isTablet = ref(false)
  const isIOS = ref(false)
  const isSafari = ref(false)
  const isMobileSafari = ref(false)
  const isPWAInstalled = ref(false)
  const browserName = ref('')

  const isDesktop = computed(() => !isMobile.value && !isTablet.value)

  const checkDevicePlatform = () => {
    const { device, os, browser } = UAParser()
    isMobile.value = device.type === 'mobile'
    isTablet.value = device.type === 'tablet'
    isSafari.value = browser.name === 'Safari'
    isMobileSafari.value = browser.name === 'Mobile Safari'
    browserName.value = browser.name || ''
    isIOS.value = os.name === 'iOS' && isMobileSafari.value
    isPWAInstalled.value =
      window.navigator.standalone ||
      window.matchMedia('(display-mode: standalone)').matches
  }

  checkDevicePlatform()

  onMounted(() => {
    window.addEventListener('resize', checkDevicePlatform)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('resize', checkDevicePlatform)
  })

  return {
    isMobile,
    isTablet,
    isDesktop,
    isIOS,
    isSafari,
    isPWAInstalled,
    browserName,
    isMobileSafari,
  }
}
