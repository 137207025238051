import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'

import type { SearchPanelHistory } from '../types/index'
import useSearchPanelStore from './searchPanel'
import useFlightSearchOptionsStore from './flightSearchOptions'
import useHotelSearchOptions from './hotelSearchOptions'
import useCarSearchOptions from './carSearchOptions'
import useCurrencyStore from '@/modules/Core/store/currency'

const STORE_KEY = 'searchPanelHistory'

const useSearchPanelHistoryStore = defineStore(STORE_KEY, () => {
  const flightSearchOptions = useFlightSearchOptionsStore()
  const hotelSearchOptions = useHotelSearchOptions()
  const carSearchOptions = useCarSearchOptions()
  const searchPanel = useSearchPanelStore()
  const currencyStore = useCurrencyStore()

  const {
    main,
    destinations,
    interestsAndActivities,
    attributeOptions,
    isRoundTrip,
    destinationList,
    roundTripDestinationList,
    isSearchByDestination,
    isSearchByAttributes,
    searchId,
    guestId,
  } = storeToRefs(searchPanel)
  const { options: flightOptions } = storeToRefs(flightSearchOptions)
  const { options: hotelOptions } = storeToRefs(hotelSearchOptions)
  const { options: carOptions } = storeToRefs(carSearchOptions)

  const history = ref<SearchPanelHistory>(getCurrentState())

  function getCurrentState() {
    return {
      currency: currencyStore.currency,
      main: { ...main.value },
      flightOptions: { ...flightOptions.value },
      hotelOptions: { ...hotelOptions.value },
      carOptions: { ...carOptions.value },
      destinations: [...destinations.value],
      interestsAndActivities: [...interestsAndActivities.value],
      attributeOptions: isSearchByDestination.value
        ? []
        : [...attributeOptions.value],
      isRoundTrip: isRoundTrip.value,
      isMultiDestination: !!destinations.value.length,
      destinationList: [...destinationList.value],
      roundTripDestinationList: isRoundTrip.value
        ? [...roundTripDestinationList.value]
        : [],
      isSearchByDestination: isSearchByDestination.value,
      isSearchByAttributes: isSearchByAttributes.value,
      searchId: searchId?.value,
      guestId: guestId?.value,
    }
  }

  function setHistorySearchId(searchId: string) {
    history.value.searchId = searchId
  }

  function update() {
    history.value = getCurrentState()
  }

  return {
    update,
    history,
    setHistorySearchId,
  }
})

export default useSearchPanelHistoryStore
