<template>
  <form novalidate @submit="onSubmit">
    <JRow spaceMd="12" spaceLg="12" spaceXl="12" class="mb-12 gap-y-16">
      <JCol>
        <JTextInput
          v-model.trim="ticketEmail.value.value"
          :error="ticketEmail.errorMessage.value"
          type="email"
          :label="t('support.form.email.label')"
          :placeholder="t('support.form.email.placeholder')"
          required
        />
      </JCol>
      <JCol>
        <JTextArea
          v-model.trim="ticketMessage.value.value"
          :error="ticketMessage.errorMessage.value"
          :label="t('support.form.ticketMessage.label')"
          :placeholder="t('support.form.ticketMessage.placeholder')"
          required
          rows="6"
        />
      </JCol>
    </JRow>

    <div class="flex md:justify-end">
      <JButton
        size="md"
        :block="isMobile"
        class="rounded-sm"
        type="submit"
        :isLoading="isLoading"
        :disabled="isLoading"
      >
        {{ t('words.submit') }}
      </JButton>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { object, string } from 'yup'
import { useField, useForm } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import { JButton, JCol, JRow, JTextArea, JTextInput } from '@journey/ui-kit'
import type { Emits, Form, Props } from './ContactSupportForm.types'
import { CONTACT_SUPPORT_TICKET_ACTION_TYPE } from '@/modules/Support/constants'
import { REGEX } from '@/modules/Core/constants'
import { useDevicePlatform } from '@/modules/Core/composables'
import { useCreateSupportMessageMutation } from '@/modules/Support/requests/mutations'
import useAuthStore from '@/modules/Auth/store'

const props = defineProps<Props>()

const emit = defineEmits<Emits>()

const { t } = useI18n()
const { isMobile } = useDevicePlatform()
const authStore = useAuthStore()

const validationSchema = object({
  ticketEmail: string()
    .matches(REGEX.EMAIL, t('support.form.email.validation.valid'))
    .transform((value) => value || null)
    .required(t('support.form.email.validation.required')),
  ticketMessage: string()
    .max(500, t('support.form.ticketMessage.validation.max'))
    .required(t('support.form.ticketMessage.validation.required')),
})

const { handleSubmit, resetForm } = useForm<Form>({
  validationSchema,
  initialValues: {
    ...props.form,
    ticketEmail: authStore.loggedIn ? authStore.user.email : '',
    ticketActionType: CONTACT_SUPPORT_TICKET_ACTION_TYPE,
  },
})

const ticketMessage = useField<Form['ticketMessage']>('ticketMessage')
const ticketEmail = useField<Form['ticketEmail']>('ticketEmail')

// MUTATION
const { isLoading, mutateAsync } = useCreateSupportMessageMutation()

const onSubmit = handleSubmit(async (values) => {
  await mutateAsync(
    {
      ...values,
    },
    {
      onSuccess: async () => {
        emit('submit')
        resetForm()
      },
    }
  )
})
</script>
