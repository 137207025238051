export enum HELP_SECTIONS {
  Search = 'Search',
  LoyaltyAccount = 'Loyalty Account',
  ItinerarySummary = 'Itinerary Summary',
  Destination = 'Destination',
  LanguageOrCurrency = 'Language or Currency',
  Accounting = 'Accounting',
  Profile = 'Profile',
  SearchResults = 'Search Results',
  Trips = 'Trips',
  Messages = 'Messages',
}
