import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import { PROFILE_QUERY_KEY } from '../queries'
import type { UpdateProfilePayload } from '@/modules/Profile/types/api/payload/profile'
import type {
  FetchProfileSuccessResponse,
  UpdateProfileSuccessResponse,
} from '@/modules/Profile/types/api/response/profile'
import { axiosInstance } from '@/modules/Core/plugins/axios'
import useAuthStore from '@/modules/Auth/store'

const ENDPOINT = '/User/Profile'

export function updateProfile(payload: UpdateProfilePayload) {
  return axiosInstance.post<UpdateProfileSuccessResponse>(ENDPOINT, payload)
}

export function useUpdateProfileMutation(
  options?: UseMutationOptions<
    AxiosResponse<UpdateProfileSuccessResponse>,
    AxiosCustomError,
    UpdateProfilePayload,
    any
  >
) {
  const queryClient = useQueryClient()
  const authStore = useAuthStore()

  return useMutation({
    mutationFn: (payload: UpdateProfilePayload) => updateProfile(payload),
    ...options,
    onSuccess(data) {
      const {
        firstName: firstname,
        lastName: lastname,
        email,
        phone,
      } = data.data.data
      authStore.setUser({ firstname, lastname, email, phone })

      queryClient.setQueryData<FetchProfileSuccessResponse>(
        [PROFILE_QUERY_KEY],
        () => data.data
      )
    },
  })
}
