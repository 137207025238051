<template>
  <div>
    <h3 class="mb-16 text-pane-header font-medium text-dark">
      {{ title }}
    </h3>

    <JAccordionGroup v-model="activeHelpSection" :accordions="accordions" />
  </div>
</template>

<script setup lang="ts">
import { JAccordionGroup } from '@journey/ui-kit'
import { computed, ref } from 'vue'
import type { Props } from './SelectedHelpItem.types'
import { generateAccordions } from '@/modules/Help/utils'

const props = defineProps<Props>()

const activeHelpSection = ref(-1)

const accordions = computed(() =>
  generateAccordions(Object.entries(props.item))
)
</script>
