import type { Ref } from 'vue'
import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
import type { FetchItinerarySuccessResponse } from '../../types/api/response/itinerary'
import { axiosInstance } from '@/modules/Core/plugins/axios'

export const QUERY_KEY = 'itinerary'
export const ENDPOINT = '/Itinerary/GetItinerary'

export function fetchItinerary(searchId: string, itineraryId: string) {
  return axiosInstance
    .get<FetchItinerarySuccessResponse>(ENDPOINT, {
      params: {
        searchId,
        itineraryId,
      },
    })
    .then((response) => response.data)
}

export function useItineraryQuery(
  searchId: Ref<string>,
  itineraryId: Ref<string>,
  options?: UseQueryOptions<Awaited<ReturnType<typeof fetchItinerary>>>
) {
  return useQuery({
    queryKey: [QUERY_KEY, searchId, itineraryId],
    queryFn: () => fetchItinerary(searchId.value, itineraryId.value),
    ...options,
  })
}
