import { type UseMutationOptions, useMutation } from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import type { SupportSuccessResponse } from '@/modules/Support/types/api/response/contactSupport'
import { axiosInstance } from '@/modules/Core/plugins/axios'
import type { SupportPayload } from '@/modules/Support/types/api/payload/contactSupport'

const ENDPOINT = '/Ticket/Contact'

export function createTicket(payload: SupportPayload) {
  return axiosInstance.post<SupportSuccessResponse>(ENDPOINT, payload)
}

export function useCreateSupportMessageMutation(
  options?: UseMutationOptions<
    AxiosResponse<SupportSuccessResponse>,
    AxiosCustomError,
    SupportPayload,
    any
  >
) {
  return useMutation({
    mutationFn: createTicket,
    ...options,
  })
}
