import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import type { UpdateSearchWeightPayload } from '@/modules/Profile/types/api/payload/searchWeights'
import { SEARCH_WEIGHTS_QUERY_KEY } from '@/modules/Profile/requests/queries'
import type { FetchSearchWeightsSuccessResponse } from '@/modules/Profile/types/api/response/searchWeights'

import { axiosInstance } from '@/modules/Core/plugins/axios'

const ENDPOINT = '/User/SearchWeight'

export function updateSearchWeights(payload: UpdateSearchWeightPayload) {
  return axiosInstance.put<FetchSearchWeightsSuccessResponse>(ENDPOINT, payload)
}

export function useUpdateSearchWeightsMutation(
  options?: UseMutationOptions<
    AxiosResponse<FetchSearchWeightsSuccessResponse>,
    AxiosCustomError,
    UpdateSearchWeightPayload,
    any
  >
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateSearchWeights,
    ...options,
    onSuccess(data) {
      queryClient.setQueryData<FetchSearchWeightsSuccessResponse>(
        [SEARCH_WEIGHTS_QUERY_KEY],
        () => data.data
      )
    },
  })
}
