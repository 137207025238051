import type { RouteRecordRaw, Router } from 'vue-router'

const SupportRoutes: RouteRecordRaw = {
  path: '/support',
  name: 'SupportModulePages',
  redirect: { name: '' },
  children: [],
  meta: {
    layout: 'Default',
  },
}

export default (router: Router) => {
  router.addRoute(SupportRoutes)
}
