import type { RouteRecordRaw, Router } from 'vue-router'

const IndexRoutes: RouteRecordRaw = {
  path: '/',
  name: 'IndexModulePages',
  // redirect: { name: '' },
  children: [
    {
      path: '',
      name: 'Index',
      component: () => import('./views/IndexPage.vue'),
    },
  ],
  meta: {
    layout: 'Default',
  },
}

export default (router: Router) => {
  router.addRoute(IndexRoutes)
}
