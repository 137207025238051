import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
import type { FetchTravelPreferencesSuccessResponse } from '@/modules/Profile/types/api/response/travelPreferences'
import { axiosInstance } from '@/modules/Core/plugins/axios'

export const QUERY_KEY = 'travel-preferences'
const ENDPOINT = '/TravelPreferences'

function fetchTravelPreferences() {
  return axiosInstance
    .get<FetchTravelPreferencesSuccessResponse>(ENDPOINT, {})
    .then((response) => response.data)
}

export function useTravelPreferencesQuery(
  options?: UseQueryOptions<Awaited<ReturnType<typeof fetchTravelPreferences>>>
) {
  return useQuery({
    queryFn: fetchTravelPreferences,
    queryKey: [QUERY_KEY],
    ...options,
  })
}
