<template>
  <JModal v-model="internalModelValue" size="sm" persistent>
    <template #header />
    <div class="py-20">
      <div class="mx-auto flex items-center justify-center rounded-full">
        <Currency />
      </div>

      <div class="mt-32 text-center text-pane-header font-bold">
        {{ $t('search.confirmCurrencyChange.title') }}
      </div>

      <div class="mt-10 text-center text-pane-header text-gray-500">
        {{ $t('search.confirmCurrencyChange.description') }}
      </div>

      <div class="mt-20 flex space-x-5">
        <JButton
          block
          color="secondary"
          variant="outlined"
          class="rounded-lg py-16"
          tabindex="-1"
          @click="internalModelValue = false"
        >
          {{ $t('words.cancel') }}
        </JButton>
        <JButton
          block
          color="primary"
          class="rounded-lg py-16"
          tabindex="-1"
          @click="onChangeCurrency"
        >
          {{ $t('words.confirm') }}
        </JButton>
      </div>
    </div>
  </JModal>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { JButton, JModal } from '@journey/ui-kit'
import type { Emits, Props } from './ChangeCurrencyConfirmationDialog.types'
import Currency from '@/assets/svg/currency-bro.svg'

// TOGGLE
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const emit = defineEmits<Emits>()

const internalModelValue = useVModel(props, 'modelValue', emit)

function onChangeCurrency() {
  internalModelValue.value = false
  emit('confirm')
}
</script>
