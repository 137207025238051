import { defineStore } from 'pinia'
import {
  PushNotificationAnswerState,
  type UserNotificationPreferences,
} from '@/modules/Core/types/model/userNotificationPreferences'

const DEFAULT_FCM_NOTIFICATION = {
  answer: PushNotificationAnswerState.NOT_ANSWERED,
  operationComplete: false,
  open: false,
}

const INITIAL_STATE: UserNotificationPreferences & {
  fcmNotification: PushNotificationState
  isConfirmAddToHomeScreen: boolean
} = {
  notification: {
    isWebActive: false,
    isMobileActive: false,
  },
  fcmNotification: { ...DEFAULT_FCM_NOTIFICATION },
  isConfirmAddToHomeScreen: false,
}

const useUserPreferencesStore = defineStore('userPreferences', {
  state: () => ({ ...INITIAL_STATE }),
  actions: {
    setNotificationPreferences(
      notificationPreferences: UserNotificationPreferences['notification']
    ) {
      this.notification = {
        isWebActive: notificationPreferences.isWebActive,
        isMobileActive: notificationPreferences.isMobileActive,
      }
    },
    setFcmNotification(values: PushNotificationState) {
      this.fcmNotification = values
    },
    setOpenFcmNotification(open: boolean) {
      this.fcmNotification.open = open
    },
    resetFcmNotification() {
      this.fcmNotification = { ...DEFAULT_FCM_NOTIFICATION }
    },
    setConfirmAddToHomeScreen(value: boolean) {
      this.isConfirmAddToHomeScreen = value
    },
  },
  persist: {
    storage: window.localStorage,
    paths: ['fcmNotification', 'isConfirmAddToHomeScreen'],
  },
})

export default useUserPreferencesStore
