<template>
  <ProfileMobile v-if="!isDesktop">
    <JConfirmation
      v-model="isConfirmModalOpen"
      showCloseButton
      :title="confirmationModalTitle"
      :confirmText="$t('yes')"
      :cancelText="$t('no')"
      @confirm="confirm"
      @cancel="cancel"
      @close="close(false)"
    />
  </ProfileMobile>

  <ProfileModal v-else>
    <JConfirmation
      v-model="isConfirmModalOpen"
      showCloseButton
      :title="confirmationModalTitle"
      :confirmText="$t('yes')"
      :cancelText="$t('no')"
      @confirm="confirm"
      @cancel="cancel"
      @close="close(false)"
    />
  </ProfileModal>
</template>

<script setup lang="ts">
import { JConfirmation } from '@journey/ui-kit'
import useProfileConfirmationModal from '@/modules/Profile/composables/useProfileConfirmationModal'
import ProfileMobile from '@/modules/Profile/components/ProfileMobile/ProfileMobile.vue'
import { useDevicePlatform } from '@/modules/Core/composables'
import ProfileModal from '@/modules/Profile/components/ProfileModal/ProfileModal.vue'

const { isDesktop } = useDevicePlatform()

const { confirm, close, cancel, isConfirmModalOpen, confirmationModalTitle } =
  useProfileConfirmationModal()
</script>
