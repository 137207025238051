<template>
  <TheHeaderContent
    id="headerId"
    brandTitle="TimeTravel"
    v-bind="$attrs"
    :showProfile="showProfile"
    :showCurrency="showCurrency"
    :showMultiLang="showMultiLang"
    @update-profile="profileStore.setIsProfileModalOpen(true)"
    @show-profile="authStore.toggleAuthForm(true)"
    @review-message="modals.reviewMessage = true"
    @review-trips="modals.reviewTrips = true"
    @logout="authStore.toggleLogoutModal()"
    @loyalty-account="loyaltyStore.setIsLoyaltyModalOpen(true)"
    @contact-us="modals.support = true"
    @show-itinerary="itinerarySummaryStore.toggle()"
  />

  <!-- PROFILE -->
  <ProfileConfirmationProvider />

  <!-- LOYALTY -->
  <LoyaltyFormConfirmationProvider>
    <LoyaltyModal />
  </LoyaltyFormConfirmationProvider>

  <!-- MESSAGES -->
  <ReviewMessageModal v-model="modals.reviewMessage" />

  <!-- TRIPS -->
  <TripsModal v-model="modals.reviewTrips" />

  <!-- AUTH -->
  <AuthModal v-model="authStore.authFormIsOpen" />

  <!-- CONTACT US -->
  <ContactSupportModal v-model="modals.support" />
</template>

<script lang="ts" setup>
import { defineAsyncComponent, reactive } from 'vue'
import TheHeaderContent from './HeaderContent/TheHeaderContent.vue'
import type { Props } from './TheHeader.types'
import LoyaltyFormConfirmationProvider from '@/modules/Loyalty/components/LoyaltyFormConfirmationProvider/LoyaltyFormConfirmationProvider.vue'
import LoyaltyModal from '@/modules/Loyalty/components/LoyaltyModal/LoyaltyModal.vue'
import useLoyaltyStore from '@/modules/Loyalty/store'
import useProfileStore from '@/modules/Profile/store'
import useAuthStore from '@/modules/Auth/store'
import ContactSupportModal from '@/modules/Support/components/ContactSupportModal/ContactSupportModal.vue'
import ProfileConfirmationProvider from '@/modules/Profile/components/ProfileConfirmationProvider/ProfileConfirmationProvider.vue'
import useItinerarySummary from '@/modules/Search/store/itinerarySummary'

withDefaults(defineProps<Props>(), {
  showProfile: true,
  showMultiLang: true,
  showCurrency: true,
})

defineOptions({
  inheritAttrs: false,
})

// ASYNC MODAL COMPONENTS
const ReviewMessageModal = defineAsyncComponent(
  () =>
    import(
      '@/modules/Messages/components/ReviewMessageModal/ReviewMessageModal.vue'
    )
)
const AuthModal = defineAsyncComponent(
  () => import('@/modules/Auth/components/AuthModal/AuthModal.vue')
)
const TripsModal = defineAsyncComponent(
  () =>
    import('@/modules/Trips/components/ReviewTripsModal/ReviewTripsModal.vue')
)

const itinerarySummaryStore = useItinerarySummary()

// MODALS
const modals = reactive({
  auth: false,
  reviewMessage: false,
  reviewTrips: false,
  logout: false,
  pushNotification: false,
  support: false,
})

// AUTH
const authStore = useAuthStore()

// PROFILE FORMS CONFIRMATION DIALOG
const profileStore = useProfileStore()

// LOYALTY FORMS CONFIRMATION DIALOG
const loyaltyStore = useLoyaltyStore()
</script>
