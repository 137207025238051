import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import { TRAVEL_PREFERENCES_QUERY_KEY } from '@/modules/Profile/requests/queries'
import type { UpdateTravelPreferencesPayload } from '@/modules/Profile/types/api/payload/travelPreferences'
import type {
  FetchTravelPreferencesSuccessResponse,
  UpdatePreferencesSuccessResponse,
} from '@/modules/Profile/types/api/response/travelPreferences'
import { axiosInstance } from '@/modules/Core/plugins/axios'

const ENDPOINT = '/TravelPreferences'

export function updateTravelPreferences(
  payload: UpdateTravelPreferencesPayload
) {
  return axiosInstance.put<UpdatePreferencesSuccessResponse>(ENDPOINT, payload)
}

export function useUpdateTravelPreferencesMutation(
  options?: UseMutationOptions<
    AxiosResponse<UpdatePreferencesSuccessResponse>,
    AxiosCustomError,
    UpdateTravelPreferencesPayload,
    any
  >
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: UpdateTravelPreferencesPayload) =>
      updateTravelPreferences(payload),
    ...options,
    onSuccess(data) {
      queryClient.setQueryData<FetchTravelPreferencesSuccessResponse>(
        [TRAVEL_PREFERENCES_QUERY_KEY],
        () => data.data
      )
    },
  })
}
