import type { RouteRecordRaw, Router } from 'vue-router'

const ProfileRoutes: RouteRecordRaw = {
  path: '/profile',
  name: 'ProfileModulePages',
  redirect: { name: 'Profile' },
  children: [
    {
      path: '',
      name: 'Profile',
      component: () => import('./views/ProfilePage.vue'),
    },
  ],
  meta: {
    layout: 'Default',
    requiresAuth: true,
  },
}

export default (router: Router) => {
  router.addRoute(ProfileRoutes)
}
