import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
import type { FetchSearchWeightsSuccessResponse } from '@/modules/Profile/types/api/response/searchWeights'
import { axiosInstance } from '@/modules/Core/plugins/axios'

export const QUERY_KEY = 'search-weights'
const ENDPOINT = '/User/SearchWeight'

function fetchSearchWeights() {
  return axiosInstance
    .get<FetchSearchWeightsSuccessResponse>(ENDPOINT)
    .then((response) => response.data)
}

export function useSearchWeightsQuery(
  options?: UseQueryOptions<Awaited<ReturnType<typeof fetchSearchWeights>>>
) {
  return useQuery({
    queryFn: fetchSearchWeights,
    queryKey: [QUERY_KEY],
    ...options,
  })
}
