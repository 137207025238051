<template>
  <slot />

  <!-- ITINERARY SUMMARY -->
  <ItinerarySummary
    :modelValue="itinerarySummaryStore.isOpen"
    @update:model-value="(value) => itinerarySummaryStore.toggle(value)"
  />
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'
import useItinerarySummaryStore from '@/modules/Search/store/itinerarySummary'

const ItinerarySummary = defineAsyncComponent(
  () =>
    import('@/modules/Search/components/ItinerarySummary/ItinerarySummary.vue')
)

const itinerarySummaryStore = useItinerarySummaryStore()
</script>
