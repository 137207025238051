import { defineStore } from 'pinia'
import type { SearchPanelHotelFilter } from '@/modules/Search/types'
import {
  HotelBedType,
  HotelSortBy,
  HotelViewType,
} from '@/modules/Search/types'

function initState(): {
  filters: SearchPanelHotelFilter
  isLoading: boolean
} {
  return {
    filters: {
      sortBy: HotelSortBy.Price,
      starRating: undefined,
      roomType: undefined,
      bedType: HotelBedType.Any,
      viewType: HotelViewType.Any,
      meals: undefined,
      hotels: [],
    },
    isLoading: false,
  }
}

const useHotelSearchFiltersStore = defineStore('hotelSearchFilters', {
  state: () => ({ ...initState() }),
  actions: {
    setFilters(payload: Partial<SearchPanelHotelFilter>) {
      this.filters = {
        ...this.filters,
        ...payload,
      }
    },
    setLoading(value: boolean) {
      this.isLoading = value
    },
    resetFilters() {
      this.filters = initState().filters
    },
  },
})

export default useHotelSearchFiltersStore
