import type { LocaleItem } from './i18n.types'

export const SUPPORTED_LOCALES: LocaleItem[] = [
  {
    code: 'en',
    title: 'English',
    flag: 'england',
    rtl: false,
  },
  {
    code: 'ar',
    title: 'Arabic',
    flag: 'arabia',
    rtl: true,
  },
]

export const DEFAULT_LOCALE = SUPPORTED_LOCALES[0]
