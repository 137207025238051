import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import type { UserInterestsAndActivitiesPayload } from '@/modules/Profile/types/api/payload/interestsAndActivities'
import { USER_INTERESTS_AND_ACTIVITIES_QUERY_KEY } from '@/modules/Profile/requests/queries'
import type {
  FetchUserInterestsAndActivitiesSuccessResponse,
  UpdateUserInterestsAndActivitiesSuccessResponse,
} from '@/modules/Profile/types/api/response/interestsAndActivities'

import { axiosInstance } from '@/modules/Core/plugins/axios'

const ENDPOINT = '/Interest'

export function updateUserInterestsAndActivities(
  payload: UserInterestsAndActivitiesPayload
) {
  return axiosInstance.post<UpdateUserInterestsAndActivitiesSuccessResponse>(
    ENDPOINT,
    payload
  )
}

export function useUpdateUserInterestsAndActivitiesMutation(
  options?: UseMutationOptions<
    AxiosResponse<UpdateUserInterestsAndActivitiesSuccessResponse>,
    AxiosCustomError,
    UserInterestsAndActivitiesPayload,
    any
  >
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateUserInterestsAndActivities,
    ...options,
    onSuccess(data) {
      queryClient.setQueryData<FetchUserInterestsAndActivitiesSuccessResponse>(
        [USER_INTERESTS_AND_ACTIVITIES_QUERY_KEY],
        () => data.data
      )
    },
  })
}
