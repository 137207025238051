import { defineStore } from 'pinia'
import type {
  SearchPanelFlightOptions,
  SearchPanelFlightPreferredTime,
} from '@/modules/Search/types'
import { FlightClassOfService, MaximumOfStops } from '@/modules/Search/types'

function initState(): { options: SearchPanelFlightOptions } {
  return {
    options: {
      minimumClassofService: FlightClassOfService.Economy,
      numberStops: MaximumOfStops.UpToTwo,
      flightTimeRanges: [
        {
          departureTimeList: [],
          arrivalTimeList: [],
        },
      ],
    },
  }
}

const useFlightSearchOptionsStore = defineStore('flightSearchOptions', {
  state: () => ({ ...initState() }),
  actions: {
    setOptions(payload: Partial<SearchPanelFlightOptions>) {
      this.options = {
        ...this.options,
        ...payload,
      }
    },
    setFlightTimeRanges(timeRanges: SearchPanelFlightPreferredTime[]) {
      this.options.flightTimeRanges = timeRanges
    },
    resetOptions() {
      this.options = initState().options
    },
  },
})

export default useFlightSearchOptionsStore
