import { defineStore } from 'pinia'
import type { UpdateProfileSection } from '@/modules/Profile/types'

interface State {
  currentProfileSection: Maybe<UpdateProfileSection>
  previousProfileSection: Maybe<UpdateProfileSection>
  isConfirmModalOpen: boolean
  activeSupplierType: string
  confirmationPayload: unknown
  activeFormIndex: number
  nextFormIndex: number
  shouldCloseProfileModal: boolean
  isProfileModalOpen: boolean
  isMobileSubFormOpen: boolean
}

const INITIAL_STATE: State = {
  currentProfileSection: null,
  previousProfileSection: null,
  isConfirmModalOpen: false,
  activeSupplierType: '',
  confirmationPayload: null,
  activeFormIndex: -1,
  nextFormIndex: -1,
  shouldCloseProfileModal: false,
  isProfileModalOpen: false,
  isMobileSubFormOpen: false,
}

const useProfileStore = defineStore('profile', {
  state: () => ({ ...INITIAL_STATE }),
  getters: {
    currentProfileSectionTitle(state) {
      if (!state.currentProfileSection) {
        return ''
      }

      return state.currentProfileSection.title
    },
    previousProfileSectionTitle(state) {
      if (!state.previousProfileSection) {
        return ''
      }

      return state.previousProfileSection.title
    },
  },
  actions: {
    setCurrentProfileSection(profileSection: Maybe<UpdateProfileSection>) {
      this.currentProfileSection = profileSection
    },
    setPreviousProfileSection(profileSection: Maybe<UpdateProfileSection>) {
      this.previousProfileSection = profileSection
    },
    clearCurrentProfileSection() {
      this.currentProfileSection = null
    },
    setIsConfirmModalOpen(to: boolean) {
      this.isConfirmModalOpen = to
    },
    setSupplierType(type: string) {
      this.activeSupplierType = type
    },
    setConfirmationPayload(confirmationPayload: unknown) {
      this.confirmationPayload = confirmationPayload
    },
    setActiveFormIndex(activeFormIndex: number) {
      this.activeFormIndex = activeFormIndex
    },
    setNextProfileForm(index: number) {
      this.nextFormIndex = index
    },
    setShouldCloseProfileModal(shouldCloseProfileModal: boolean) {
      this.shouldCloseProfileModal = shouldCloseProfileModal
    },
    setIsProfileModalOpen(isProfileModalOpen: boolean) {
      this.isProfileModalOpen = isProfileModalOpen
    },
    setIsMobileSubFormOpen(isMobileSubFormOpen: boolean) {
      this.isMobileSubFormOpen = isMobileSubFormOpen
    },
  },
})

export default useProfileStore
