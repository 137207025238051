import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import type { UpdateFamilyMemberPayload } from '../../types/api/payload/familyMember'
import type { UpdateFamilyMemberSuccessResponse } from '../../types/api/response/familyMember'
import { FAMILY_MEMBER_QUERY_KEY, LOYALTY_ACCOUNT_QUERY_KEY } from '../queries'
import { axiosInstance } from '@/modules/Core/plugins/axios'

const ENDPOINT = '/Family/Member'

export function updateFamilyMember(payload: UpdateFamilyMemberPayload) {
  return axiosInstance.put<UpdateFamilyMemberSuccessResponse>(ENDPOINT, payload)
}

export function useUpdateFamilyMemberMutation(
  options?: UseMutationOptions<
    AxiosResponse<UpdateFamilyMemberSuccessResponse>,
    AxiosCustomError,
    UpdateFamilyMemberPayload,
    any
  >
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: UpdateFamilyMemberPayload) =>
      updateFamilyMember(payload),
    ...options,
    async onSuccess() {
      await Promise.all([
        queryClient.invalidateQueries([LOYALTY_ACCOUNT_QUERY_KEY], {
          type: 'all',
        }),

        queryClient.invalidateQueries([FAMILY_MEMBER_QUERY_KEY], {
          type: 'all',
        }),
      ])
    },
  })
}
