import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import type { UpdateFriendPayload } from '../../types/api/payload/friend'
import type { FriendSuccessResponse } from '../../types/api/response/friend'
import { FRIENDS_QUERY_KEY } from '../queries'
import { axiosInstance } from '@/modules/Core/plugins/axios'

const ENDPOINT = '/User/Friend'

export function updateFriend(friend: UpdateFriendPayload) {
  return axiosInstance
    .put<FriendSuccessResponse>(ENDPOINT, friend)
    .then((response) => response)
}

export function useUpdateFriendMutation(
  options?: UseMutationOptions<
    AxiosResponse<FriendSuccessResponse>,
    AxiosCustomError,
    UpdateFriendPayload,
    any
  >
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: UpdateFriendPayload) => updateFriend(payload),
    ...options,
    async onSuccess() {
      await queryClient.invalidateQueries([FRIENDS_QUERY_KEY], { type: 'all' })
    },
  })
}
