import type { Ref } from 'vue'
import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
import type { FetchLoyaltyAccountsSuccessResponse } from '@/modules/Profile/types/api/response/loyaltyAccount'
import { axiosInstance } from '@/modules/Core/plugins/axios'

export const QUERY_KEY = 'loyalty-account'
const ENDPOINT = '/LoyaltyAccount'

export function fetchLoyaltyAccounts(page: number, size: number, type: string) {
  return axiosInstance
    .get<FetchLoyaltyAccountsSuccessResponse>(ENDPOINT, {
      params: { page, size, type },
    })
    .then((response) => response.data)
}

export function useFetchLoyaltyAccountsQuery(
  page: Ref<number>,
  size: Ref<number>,
  type: Ref<string>,
  options?: UseQueryOptions<Awaited<ReturnType<typeof fetchLoyaltyAccounts>>>
) {
  return useQuery({
    queryKey: [QUERY_KEY, type, page, size],
    queryFn: () => fetchLoyaltyAccounts(page.value, size.value, type.value),
    ...options,
  })
}
