import { defineStore } from 'pinia'

const useMoreStore = defineStore('more', {
  state: () => ({
    // add initial state here
  }),
  actions: {
    // add actions here
  },
})

export default useMoreStore
