import type { HelpItemContent } from '../types/model/helpItem'

export default function generateAccordions(
  items: [string, HelpItemContent[]][]
) {
  return items.map(([key, value]) => ({
    title: key,
    content: value
      .map(
        ({ helpText, screenSection }) => `
        <div class="mb-16">
          <h4 class="font-medium text-metadata-limited mb-8">${screenSection}</h4>
          <p class="text-gray-600 text-metadata-limited">${helpText}</p>
        </div>
      `
      )
      .join('\n\n'),
  }))
}
