import { type UseMutationOptions, useMutation } from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import type { SearchHotelPayload } from '@/modules/Search/types/api/payload/search'
import type { SearchHotelsDirectSuccessResponse } from '@/modules/Search/types/api/response/search'
import { axiosInstance } from '@/modules/Core/plugins/axios'

export const MUTATION_KEY = 'hotels-direct'
export const ENDPOINT = '/Search/GetHotelsDirect'

export function fetchHotelsDirect(payload: SearchHotelPayload) {
  return axiosInstance.post<SearchHotelsDirectSuccessResponse>(
    ENDPOINT,
    payload,
    // TODO: Remove the following baseURL configuration once the API is fully integrated into the backend
    {
      baseURL: import.meta.env.VITE_API_SEARCH_BASE_URL,
    }
  )
}

export function useFetchHotelsDirectMutation(
  options?: UseMutationOptions<
    AxiosResponse<SearchHotelsDirectSuccessResponse>,
    AxiosCustomError,
    SearchHotelPayload,
    any
  >
) {
  return useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: fetchHotelsDirect,
    ...options,
  })
}
