import { type UseMutationOptions, useMutation } from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import type { UpdateItinerarySuccessResponse } from '../../types/api/response/itinerary'
import type { AddItineraryPayload } from '../../types/api/payload/itinerary'
import { axiosInstance } from '@/modules/Core/plugins/axios'

export const MUTATION_KEY = 'update-itinerary'
export const ENDPOINT = '/Itinerary/UpdateItinerary'

export function updateItinerary(payload: AddItineraryPayload) {
  return axiosInstance.post<UpdateItinerarySuccessResponse>(ENDPOINT, payload)
}

export function useUpdateItineraryMutation(
  options?: UseMutationOptions<
    AxiosResponse<UpdateItinerarySuccessResponse>,
    AxiosCustomError,
    AddItineraryPayload,
    any
  >
) {
  return useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: updateItinerary,
    ...options,
  })
}
