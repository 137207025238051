<template>
  <nav class="fixed bottom-0 m-0 w-full p-0">
    <div
      class="mx-16 my-8 flex items-center justify-around gap-10 rounded-3xl border border-line bg-primary/5 bg-gradient-to-r from-gray-250 via-white to-gray-250 p-16 shadow-lg"
    >
      <div
        v-for="item in bottomNavigationItems"
        :key="item.title"
        class="flex w-full justify-center"
      >
        <button
          v-if="item.title !== 'Search'"
          class="tap-highlight-transparent relative flex w-full flex-col items-center justify-center"
          :disabled="item.disabled"
          :class="
            route.name === item.route?.name ? 'text-dark' : 'text-gray-500'
          "
          :aria-disabled="item.disabled"
          @click="click($event, item)"
        >
          <JIcon :name="(item.icon as IconsName)" :size="24" class="relative" />
          <span class="mt-8 font-medium">
            {{ item.title }}
          </span>
        </button>

        <button
          v-else
          class="tap-highlight-transparent relative flex h-55 w-55 items-center justify-center rounded-full bg-secondary shadow-lg"
          @click="click($event, item)"
        >
          <JIcon name="magnifier-outline" :size="28" class="text-white" />
        </button>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { type IconsName, JIcon } from '@journey/ui-kit'
import type { Emits, NavigationItem } from './TheBottomNavigatoin.types'
import useAuthStore from '@/modules/Auth/store'

const emit = defineEmits<Emits>()

const router = useRouter()
const route = useRoute()

const { t } = useI18n()

// ITEMS
const authStore = useAuthStore()

const bottomNavigationItems = computed<NavigationItem[]>(() => [
  {
    title: authStore.loggedIn ? t('profile.title') : t('auth.login'),
    icon: 'user-outline',
    route: { name: 'Profile' },
  },
  {
    title: t('trips.title'),
    icon: 'suitcase-outlined',
    route: { name: 'Trips' },
  },
  {
    title: 'Search',
    route: { name: 'Search' },
  },
  {
    title: t('messages.title'),
    icon: 'inbox-linear',
    route: { name: 'Messages' },
    badge: true,
  },
  {
    title: t('more.title'),
    icon: 'more',
    route: { name: 'More' },
  },
])

function click(event: Event, item: NavigationItem) {
  if (!item.route) {
    emit('click', { event, item })

    return
  }

  if (item.title === t('auth.login')) {
    authStore.setAuthFormIsDisplayed(true)
  }

  return router.push(item.route)
}
</script>

<style scoped>
.tap-highlight-transparent {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
}
</style>
