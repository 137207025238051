import { defineStore } from 'pinia'
import type { SearchPanelFlightFilters } from '@/modules/Search/types'
import {
  FilteredFlightListSortBy,
  FlightClassOfService,
  MaximumOfStops,
} from '@/modules/Search/types'

function initState(): {
  filters: SearchPanelFlightFilters
  isLoading: boolean
} {
  return {
    filters: {
      flightType: FlightClassOfService.Economy,
      stopFilter: MaximumOfStops.UpToTwo,
      items: [],
      sortBy: FilteredFlightListSortBy.Price,
      airlines: [],
    },
    isLoading: false,
  }
}

const useFlightSearchFiltersStore = defineStore('flightSearchFilters', {
  state: () => ({ ...initState() }),
  actions: {
    setFilters(payload: Partial<SearchPanelFlightFilters>) {
      this.filters = {
        ...this.filters,
        ...payload,
        items: payload.items || [],
      }
    },
    setLoading(value: boolean) {
      this.isLoading = value
    },
    resetFilters() {
      this.filters = { ...initState().filters }
    },
  },
})

export default useFlightSearchFiltersStore
