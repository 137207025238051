import type { RouteRecordRaw, Router } from 'vue-router'

const BookRoutes: RouteRecordRaw = {
  path: '/book',
  name: 'BookModulePages',
  redirect: { name: '' },
  children: [
    {
      path: '',
      name: 'Book',
      component: () => import('./views/BookPage.vue'),
      meta: {
        layout: 'Default',
      },
    },
  ],
  meta: {
    layout: 'Default',
  },
}

export default (router: Router) => {
  router.addRoute(BookRoutes)
}
