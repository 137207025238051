import { defineStore } from 'pinia'
import type { SearchPanelCarFilters } from '@/modules/Search/types'
import { CarType, FilteredCarListSortBy } from '@/modules/Search/types'

function initState(): {
  filters: SearchPanelCarFilters
  isLoading: boolean
} {
  return {
    filters: {
      sortBy: FilteredCarListSortBy.Price,
      sortCarType: CarType.Any,
      capacity: 2,
      companies: [],
    },
    isLoading: false,
  }
}

const useCarSearchFiltersStore = defineStore('carSearchFilters', {
  state: () => ({ ...initState() }),
  actions: {
    setFilters(payload: Partial<SearchPanelCarFilters>) {
      this.filters = {
        ...this.filters,
        ...payload,
      }
    },
    setLoading(value: boolean) {
      this.isLoading = value
    },
    resetFilters() {
      this.filters = initState().filters
    },
  },
})

export default useCarSearchFiltersStore
