import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
import type { FetchUserPreferencesSuccessResponse } from '@/modules/Core/types/api/response/userPreferences'
import { axiosInstance } from '@/modules/Core/plugins/axios'

export const QUERY_KEY = 'user-preferences-get'
const ENDPOINT = 'User/Preference/Get'

export function fetchUserPreferences() {
  return axiosInstance
    .get<FetchUserPreferencesSuccessResponse>(ENDPOINT)
    .then((response) => response.data)
}

export function useUserPreferencesQuery(
  options?: UseQueryOptions<Awaited<ReturnType<typeof fetchUserPreferences>>>
) {
  return useQuery({
    queryFn: fetchUserPreferences,
    queryKey: [QUERY_KEY],
    ...options,
  })
}
