import { createApp } from 'vue'
import { registerPlugins } from '@/modules/Core/plugins'
import App from '@/App.vue'
import { registerAxios } from '@/modules/Core/plugins/axios'
import '@journey/ui-kit/styles.css'
import '@journey/ui-kit/internal.css'
import '@/assets/styles/main.css'
import '@/registerModules'

const app = createApp(App)

registerAxios(app)
registerPlugins(app)

app.mount('#app')
