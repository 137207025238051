<template>
  <form novalidate @submit="submit">
    <JRow spaceMd="12" spaceLg="12" spaceXl="12" class="mb-12 gap-y-16">
      <JCol>
        <JTextInput
          v-model="subject.value.value"
          :error="subject.errorMessage.value"
          :label="t('loyalty.form.subject.label')"
          :placeholder="t('loyalty.form.subject.placeholder')"
          required
        />
      </JCol>
      <JCol>
        <JTextArea
          v-model="message.value.value"
          :error="message.errorMessage.value"
          :label="t('loyalty.form.message.label')"
          :placeholder="t('loyalty.form.message.placeholder')"
          required
          rows="6"
        />
      </JCol>
    </JRow>

    <div class="flex md:justify-end">
      <JButton size="md" :block="isMobile" class="rounded-sm" type="submit">
        {{ t('words.submit') }}
      </JButton>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { object, string } from 'yup'
import { useField, useForm } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import { JButton, JCol, JRow, JTextArea, JTextInput } from '@journey/ui-kit'
import type { Form, Props } from './MessageLoyaltyProgramTeamFormFields.types'
import { useDevicePlatform } from '@/modules/Core/composables'

const props = defineProps<Props>()

const { t } = useI18n()
const { isMobile } = useDevicePlatform()

const validationSchema = object({
  subject: string()
    .transform((value) => value || null)
    .required(t('loyalty.form.subject.validation.required')),
  message: string()
    .max(500, t('loyalty.form.message.validation.max'))
    .required(t('loyalty.form.message.validation.required')),
})

const { handleSubmit } = useForm<Form>({
  validationSchema,
  initialValues: {
    ...props.form,
  },
})

const subject = useField<Form['subject']>('subject')
const message = useField<Form['message']>('message')

const submit = handleSubmit(async () => {})
</script>
