import { computed, nextTick, ref } from 'vue'
import {
  DEFAULT_LOCALE,
  type LocaleItem,
  type LocalesCode,
  SUPPORTED_LOCALES,
} from '@/modules/Core/plugins/i18n'
import i18n from '@/modules/Core/plugins/i18n'

export default function useLocale() {
  const currentLocale = ref(i18n.global.locale)

  const currentLocaleItem = computed(
    () => findStoredLocale(currentLocale.value) || DEFAULT_LOCALE
  )

  function changeDocumentDirection(newLocaleDirection: boolean) {
    if (newLocaleDirection) {
      document.querySelector('html')?.setAttribute('dir', 'rtl')
    } else {
      document.querySelector('html')?.setAttribute('dir', 'ltr')
    }
  }

  function changeDocumentLanguage(newLocale: string) {
    document.querySelector('html')?.setAttribute('lang', newLocale)
  }

  async function loadLocaleMessages(locale: LocalesCode) {
    if (!i18n.global.availableLocales.includes(locale)) {
      const messages = await import(
        `@/modules/Core/localization/${locale}/${locale}.json`
      )
      i18n.global.setLocaleMessage(locale, messages.default)
    }

    return nextTick()
  }

  async function switchLanguage(newLocale: LocaleItem) {
    localStorage.setItem('user-locale', newLocale.code)
    window.location.reload()
  }

  const applyCurrentLanguage = async () => {
    const storedLocale = window.localStorage.getItem('user-locale')

    if (storedLocale) {
      currentLocale.value = storedLocale as LocalesCode
    }

    await loadLocaleMessages(currentLocaleItem.value.code)

    currentLocale.value = currentLocaleItem.value.code

    changeDocumentLanguage(currentLocaleItem.value.code)
    changeDocumentDirection(currentLocaleItem.value.rtl)

    localStorage.setItem('user-locale', currentLocaleItem.value.code)
  }

  function findStoredLocale(stored: string) {
    return SUPPORTED_LOCALES.find((locale) => locale.code === stored)
  }

  return {
    currentLocale,
    switchLanguage,
    currentLocaleItem,
    applyCurrentLanguage,
  }
}
