import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
import type { FetchProfileSuccessResponse } from '../../types/api/response/profile'
import { axiosInstance } from '@/modules/Core/plugins/axios'

export const QUERY_KEY = 'update-profile/profile'
const ENDPOINT = '/User/Profile'

export function fetchProfile() {
  return axiosInstance
    .get<FetchProfileSuccessResponse>(ENDPOINT)
    .then((response) => response.data)
}

export function useProfileQuery(
  options?: UseQueryOptions<Awaited<ReturnType<typeof fetchProfile>>>
) {
  return useQuery({
    queryFn: () => fetchProfile(),
    queryKey: [QUERY_KEY],
    ...options,
  })
}
