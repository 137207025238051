import { defineStore } from 'pinia'
import type { Currency } from '@/modules/Core/types/model/currency'

const INITIAL_STATE: { isChangedRecently: boolean; currency: Currency } = {
  isChangedRecently: false,
  currency: {
    title: '',
    description: '',
    symbol: '',
  },
}

const useCurrencyStore = defineStore('currency', {
  state: () => ({ ...INITIAL_STATE }),
  actions: {
    setCurrency(currency: Currency) {
      this.currency = currency
    },
    setIsChangedRecently(isChangedRecently: boolean) {
      this.isChangedRecently = isChangedRecently
    },
  },
  persist: {
    storage: window.localStorage,
  },
})

export default useCurrencyStore
