type UserAgent = 'Android' | 'iPhone' | 'Linux' | 'MacOS' | 'Windows'
type Platform = 'Android' | 'iOS' | 'Web'

const PLATFORMS: Record<UserAgent, Platform> = {
  Android: 'Android',
  iPhone: 'iOS',
  Linux: 'Web',
  MacOS: 'Web',
  Windows: 'Web',
}

export default function getPlatform() {
  const userAgent = window.navigator.userAgent

  const os = Object.keys(PLATFORMS).find((os) =>
    userAgent.toLowerCase().includes(os.toLowerCase())
  )

  if (!os) {
    return 'Web'
  }

  return PLATFORMS[os as keyof typeof PLATFORMS]
}
