import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
import type { FetchUserInterestsAndActivitiesSuccessResponse } from '@/modules/Profile/types/api/response/interestsAndActivities'
import { axiosInstance } from '@/modules/Core/plugins/axios'

export const QUERY_KEY = 'user-interests-and-activities'
const ENDPOINT = '/Interest'

function fetchUserInterestsAndActivities() {
  return axiosInstance
    .get<FetchUserInterestsAndActivitiesSuccessResponse>(ENDPOINT, {})
    .then((response) => response.data)
}

export function useUserInterestsAndActivities(
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof fetchUserInterestsAndActivities>>
  >
) {
  return useQuery({
    queryFn: fetchUserInterestsAndActivities,
    queryKey: [QUERY_KEY],
    ...options,
  })
}
