import { type UseMutationOptions, useMutation } from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import type { SearchCarPayload } from '@/modules/Search/types/api/payload/search'
import type { SearchCarsDirectSuccessResponse } from '@/modules/Search/types/api/response/search'
import { axiosInstance } from '@/modules/Core/plugins/axios'

export const MUTATION_KEY = 'cars-direct'
export const ENDPOINT = '/Search/GetCarsDirect'

export function fetchCarsDirect(payload: SearchCarPayload) {
  return axiosInstance.post<SearchCarsDirectSuccessResponse>(
    ENDPOINT,
    payload,
    // TODO: Remove the following baseURL configuration once the API is fully integrated into the backend
    {
      baseURL: import.meta.env.VITE_API_SEARCH_BASE_URL,
    }
  )
}

export function useFetchCarsDirectMutation(
  options?: UseMutationOptions<
    AxiosResponse<SearchCarsDirectSuccessResponse>,
    AxiosCustomError,
    SearchCarPayload,
    any
  >
) {
  return useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: fetchCarsDirect,
    ...options,
  })
}
