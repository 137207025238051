import { defineStore } from 'pinia'
import { SearchOrFilterMode } from '../types'
import type { SearchType } from '../types'

interface State {
  isSearched: boolean
  isItinerarySelected: boolean
  activeSearchTab: SearchType
  isItinerarySummaryOpen: boolean
  interestsAndActivitiesModalIsOpen: boolean
  triggeredBy?: SearchOrFilterMode
}

const INITIAL_STATE: State = {
  isSearched: false,
  isItinerarySelected: false,
  activeSearchTab: 'Destination',
  isItinerarySummaryOpen: false,
  interestsAndActivitiesModalIsOpen: false,
  triggeredBy: undefined,
}

const useSearchStore = defineStore('search', {
  state: () => ({ ...INITIAL_STATE }),
  getters: {
    isSearchByDestination: (state) => state.activeSearchTab === 'Destination',
    isTriggeredBySearch: (state) =>
      state.triggeredBy === SearchOrFilterMode.Search,
  },
  actions: {
    setIsSearched(value: boolean) {
      this.isSearched = value
    },
    setIsItinerarySelected(value: boolean) {
      this.isItinerarySelected = value
    },
    setActiveSearchTab(value: SearchType) {
      this.activeSearchTab = value
    },
    setIsItinerarySummaryOpen(value: boolean) {
      this.isItinerarySummaryOpen = value
    },
    setInterestsAndActivitiesModalIsOpen(isOpen: boolean) {
      this.interestsAndActivitiesModalIsOpen = isOpen
    },
    setTriggeredBy(value: SearchOrFilterMode) {
      this.triggeredBy = value
    },
  },
})

export default useSearchStore
