import type { Ref } from 'vue'
import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import { LOYALTY_ACCOUNT_QUERY_KEY } from '../queries'
import type { CreateLoyaltyAccountPayload } from '@/modules/Profile/types/api/payload/loyaltyAccount'
import type { CreateLoyaltyAccountSuccessResponse } from '@/modules/Profile/types/api/response/loyaltyAccount'
import { axiosInstance } from '@/modules/Core/plugins/axios'

const ENDPOINT = '/LoyaltyAccount'

export function createLoyaltyAccount(payload: CreateLoyaltyAccountPayload) {
  return axiosInstance.post<CreateLoyaltyAccountSuccessResponse>(
    ENDPOINT,
    payload
  )
}

export function useCreateLoyaltyAccountMutation(
  type: Ref<string>,
  options?: UseMutationOptions<
    AxiosResponse<CreateLoyaltyAccountSuccessResponse>,
    AxiosCustomError,
    CreateLoyaltyAccountPayload,
    any
  >
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createLoyaltyAccount,
    async onSuccess() {
      await queryClient.invalidateQueries([LOYALTY_ACCOUNT_QUERY_KEY, type])
    },
    ...options,
  })
}
