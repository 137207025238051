<template>
  <JRow>
    <JCol sm="12" md="6" lg="6" xl="6" class="flex flex-col justify-center">
      <h6 class="text-subject font-medium">
        {{ $t('loyalty.accountActivityReport') }}
      </h6>
    </JCol>
    <JCol sm="12" md="6" lg="6" xl="6" class="flex justify-end">
      <JMenu
        v-model="selectedSortOption"
        v-model:sortOrder="sortOrder"
        :title="$t('words.sort')"
        :options="options"
        position="left"
        isSortMenu
      >
        <template #mobileHeader>
          <div class="mt-16 flex items-center gap-x-4 px-16">
            <JIcon name="sort" size="16" />
            <span>
              {{ $t('loyalty.dateRange') }}
            </span>
          </div>
        </template>
        <template #menuButton>
          <JButton
            shrink
            icon="sort"
            color="white"
            variant="outlined"
            class="rounded-2xl"
          >
            {{ $t('loyalty.dateRange') }}
          </JButton>
        </template>

        <template #listItem="{ menuItem, selectedItem }">
          <JMenuItem :option="menuItem" :selectedItem="selectedItem">
            <template #prependItem>
              <span :class="{ 'font-bold text-primary': selectedItem }">
                {{ menuItem.text }}
              </span>
            </template>
            <template #appendItem>
              <JIcon
                v-show="selectedItem"
                name="check-circle-bold-2"
                class="text-primary"
              />
            </template>
          </JMenuItem>
        </template>
      </JMenu>
    </JCol>

    <JCol class="mt-12">
      <JAccordionGroup
        :modelValue="activeFormIndex"
        :accordions="loyaltySections"
        @update:model-value="displayConfirmationModalIfTouched($event)"
      />
    </JCol>

    <JCol>
      <JPagination v-model="page" justify="center" :totalPages="totalPages" />
    </JCol>
  </JRow>
</template>

<script setup lang="ts">
import { inject, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import {
  JAccordionGroup,
  JButton,
  JCol,
  JIcon,
  JMenu,
  JMenuItem,
  JPagination,
  JRow,
} from '@journey/ui-kit'
import useHelpStore from '@/modules/Help/store'
import {
  LOYALTY_FORMS,
  loyaltySectionSharedStateInjectionKey,
} from '@/modules/Loyalty/constants'
import useLoyaltyStore from '@/modules/Loyalty/store'
import type { OrderOption } from '@/modules/Loyalty/types'
import { HELP_SECTIONS } from '@/modules/Core/constants'

const { t } = useI18n()

const loyaltyStore = useLoyaltyStore()

const loyaltySections = LOYALTY_FORMS.flatMap(({ content, title }) => {
  const duplicatedItems = Array.from({ length: 6 }, (_, index) => ({
    title: t(title, { index: index + 1 }),
    content,
    lazy: true,
  }))
  return [...duplicatedItems]
})

const { activeFormIndex } = storeToRefs(loyaltyStore)
const previousFormIndex = ref(-1)

const loyaltySectionSharedState = inject(loyaltySectionSharedStateInjectionKey)

function anAccordionIsGoingToOpen(value: number) {
  return value !== -1
}

function onlyOpenAccordionIsGoingToClose(value: number) {
  return value === -1
}

function allAccordionsWereClosed(oldValue: number) {
  return oldValue === -1
}

function anAccordionIsOpenAndAnotherAccordionIsGoingToCloseItAndOpenItself(
  value: number,
  oldValue: number
) {
  return value !== -1 && oldValue !== -1
}

function findAccordionThatIsClosing(idx: number) {
  return LOYALTY_FORMS.find((_section, index) => index === idx)
}

function findAccordionThatIsOpening(idx: number) {
  return LOYALTY_FORMS.find((_section, index) => index === idx)
}

function isPreviousSectionTouched() {
  return loyaltySectionSharedState!.items[
    loyaltyStore.previousLoyaltySectionTitle
  ]?.touched
}

function isCurrentSectionTouched() {
  return loyaltySectionSharedState!.items[
    loyaltyStore.currentLoyaltySectionTitle
  ]?.touched
}

async function displayConfirmationModalIfTouched(value: number) {
  if (anAccordionIsGoingToOpen(value)) {
    if (allAccordionsWereClosed(previousFormIndex.value)) {
      loyaltyStore.setActiveFormIndex(value)

      loyaltyStore.setCurrentLoyaltySection(findAccordionThatIsOpening(value))
    }
  }

  if (onlyOpenAccordionIsGoingToClose(value)) {
    loyaltyStore.setNextLoyaltyForm(-1)

    if (!isCurrentSectionTouched()) {
      loyaltyStore.setActiveFormIndex(value)

      loyaltyStore.setCurrentLoyaltySection(null)

      return
    }

    loyaltyStore.setIsConfirmModalOpen(true)
  } else if (
    anAccordionIsOpenAndAnotherAccordionIsGoingToCloseItAndOpenItself(
      value,
      previousFormIndex.value
    )
  ) {
    loyaltyStore.setPreviousLoyaltySection(
      findAccordionThatIsClosing(previousFormIndex.value)
    )

    if (!isPreviousSectionTouched()) {
      loyaltyStore.setActiveFormIndex(value)

      loyaltyStore.setPreviousLoyaltySection(null)

      return
    }

    loyaltyStore.setNextLoyaltyForm(value)

    loyaltyStore.setIsConfirmModalOpen(true)
  }
}

watchEffect(() => (previousFormIndex.value = activeFormIndex.value))

// RECENT ACTIVITY
const helpStore = useHelpStore()

helpStore.setRecentActivity(HELP_SECTIONS.LoyaltyAccount)

// SORT
const options: OrderOption[] = [
  {
    text: t('loyalty.dateRangeItems.today'),
    id: 'today',
  },
  {
    text: t('loyalty.dateRangeItems.lastDaY'),
    id: 'last24Hour',
  },
  {
    text: t('loyalty.dateRangeItems.weekToDate'),
    id: 'weekToDate',
  },
  {
    text: t('loyalty.dateRangeItems.lastWeek'),
    id: 'last7Date',
  },
  {
    text: t('loyalty.dateRangeItems.monthToDate'),
    id: 'monthToDate',
  },
  {
    text: t('loyalty.dateRangeItems.pastMonth'),
    id: 'last30Days',
  },
  {
    text: t('loyalty.dateRangeItems.yearToDate'),
    id: 'yearToDate',
  },
  {
    text: t('loyalty.dateRangeItems.pastYear'),
    id: 'last365Days',
  },
  {
    text: t('loyalty.dateRangeItems.all'),
    id: 'all',
  },
]

const selectedSortOption = ref(options[0])

const sortOrder = ref<SortOrder>('ascending')

const page = ref(1)
const totalPages = ref(3)
</script>
