import { type UseMutationOptions, useMutation } from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import type { AddItinerarySuccessResponse } from '../../types/api/response/itinerary'
import type { AddItineraryPayload } from '../../types/api/payload/itinerary'
import { axiosInstance } from '@/modules/Core/plugins/axios'

export const MUTATION_KEY = 'add-itinerary'
export const ENDPOINT = '/Itinerary/AddItinerary'

export function addItinerary(payload: AddItineraryPayload) {
  return axiosInstance.post<AddItinerarySuccessResponse>(ENDPOINT, payload)
}

export function useAddItineraryMutation(
  options?: UseMutationOptions<
    AxiosResponse<AddItinerarySuccessResponse>,
    AxiosCustomError,
    AddItineraryPayload,
    any
  >
) {
  return useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: addItinerary,
    ...options,
  })
}
