import { defineStore } from 'pinia'

const useMessagesStore = defineStore('messages', {
  state: () => ({
    // add initial state here
  }),
  actions: {
    // add actions here
  },
})

export default useMessagesStore
