<template>
  <JModal
    :modelValue="loyaltyStore.isLoyaltyModalOpen"
    :title="$t('loyalty.loyaltyAccount')"
    noBody
    :size="windowWidth > 1024 ? 'md' : 'lg'"
    @update:model-value="displayConfirmationModalIfTouched"
  >
    <template #navigation>
      <button @click="helpStore.toggleHelpModal(true)">
        <JIcon
          name="question-mark-outline"
          class="text-metadata text-gray-500"
        />
      </button>
    </template>

    <div class="mb-24 flex flex-wrap justify-between px-24 text-subject">
      <div class="mb-12 flex w-1/2 justify-between pe-12">
        <span class="text-gray-600">
          {{ $t('loyalty.accountHolder') }}
        </span>

        <span class="font-bold">
          {{ authStore.userFullName || 'Shane Batt' }}
        </span>
      </div>

      <div class="mb-12 flex w-1/2 justify-between ps-12">
        <span class="text-gray-600">
          {{ $t('loyalty.programTier') }}
        </span>

        <span class="font-bold">
          {{ $t('loyalty.loyaltyAccountCaptain') }}
        </span>
      </div>

      <div class="mb-12 flex w-1/2 justify-between pe-12">
        <span class="text-gray-600">
          {{ $t('loyalty.currentAvailableToSpend') }}
        </span>

        <span class="font-bold text-success"> $1,234 </span>
      </div>

      <div class="mb-12 flex w-1/2 justify-between ps-12">
        <span class="text-gray-600">
          {{ $t('loyalty.pointsEarnedThisYear') }}
        </span>

        <span class="font-bold"> 11,265 </span>
      </div>

      <div class="mb-12 flex w-1/2 justify-between pe-12">
        <span class="text-base text-gray-600">
          {{ $t('loyalty.pointsNeededForLoyaltyAccountAdmiral') }}
        </span>

        <span class="font-bold"> 3,235 </span>
      </div>

      <div class="mb-12 flex w-1/2 justify-between ps-12">
        <span class="text-gray-600">
          {{ $t('loyalty.lifetimePointsEarned') }}
        </span>

        <span class="font-bold"> 31,434 </span>
      </div>
    </div>

    <div class="px-24">
      <LoyaltyTabs />
    </div>
  </JModal>
</template>

<script setup lang="ts">
import { computed, inject, watch } from 'vue'
import { JIcon, JModal } from '@journey/ui-kit'
import LoyaltyTabs from '../LoyaltyModal/LoyaltyTabs/LoyaltyTabs.vue'
import useAuthStore from '@/modules/Auth/store'
import useLoyaltyStore from '@/modules/Loyalty/store'
import { loyaltySectionSharedStateInjectionKey } from '@/modules/Loyalty/constants'
import useHelpStore from '@/modules/Help/store'

const authStore = useAuthStore()

// CONFIRMATION DIALOG
const loyaltyStore = useLoyaltyStore()

const loyaltySectionSharedState = inject(loyaltySectionSharedStateInjectionKey)

function isCurrentSectionTouched() {
  return loyaltySectionSharedState!.items[
    loyaltyStore.currentLoyaltySectionTitle
  ]?.touched
}

function displayConfirmationModalIfTouched(value: boolean) {
  if (value) {
    return
  }

  loyaltyStore.setShouldCloseLoyaltyModal(true)

  if (!isCurrentSectionTouched()) {
    loyaltyStore.setActiveFormIndex(-1)
    loyaltyStore.setCurrentLoyaltySection(null)
    loyaltyStore.setIsLoyaltyModalOpen(false)

    return
  }

  loyaltyStore.setIsConfirmModalOpen(true)
}

watch(
  () => loyaltyStore.isLoyaltyModalOpen,
  () => loyaltyStore.setShouldCloseLoyaltyModal(false)
)

const helpStore = useHelpStore()

const windowWidth = computed(() => window.innerWidth)
</script>
