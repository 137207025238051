import type { RouteRecordRaw, Router } from 'vue-router'
import useHotelSearchResultsStore from './store/hotelSearchResults'

const INCLUDES_INDEX_PAGE = import.meta.env.VITE_INCLUDES_INDEX_PAGE

const SearchRoutes: RouteRecordRaw = {
  path: INCLUDES_INDEX_PAGE === 'true' ? '/search' : '',
  name: 'SearchModulePages',
  children: [
    {
      path: '',
      name: 'Search',
      component: () => import('./views/IndexPage.vue'),
      meta: {
        header: false,
      },
    },
    {
      path: '/result',
      name: 'Result',
      component: () => import('./views/ResultPage.vue'),
      meta: {
        showBottomNav: false,
      },
    },
    {
      path: '/hotels-map',
      name: 'HotelResultsMapView',
      beforeEnter: (_, __, next) => {
        const hotelSearchResultStore = useHotelSearchResultsStore()

        if (!hotelSearchResultStore.hotelsList.length) {
          next({ name: 'Search' })
        } else {
          next()
        }
      },
      component: () => import('./views/HotelResultsMapView.vue'),
      meta: {
        showBottomNav: false,
      },
    },
  ],
  meta: {
    layout: 'Default',
  },
}

export default (router: Router) => {
  router.addRoute(SearchRoutes)
}
