import { defineStore } from 'pinia'
import { BookingStepType } from '@/modules/Book/types/model'
import type {
  CarPickUpDropOffTimePreferences,
  ContactInfoStoreType,
} from '@/modules/Book/types/model'

function initState(): ContactInfoStoreType {
  return {
    carPickUpDropOff: {
      pickUpDate: '',
      pickUpTime: '',
      dropOffDate: '',
      dropOffTime: '',
    },
    activeStep: BookingStepType.Flight,
    isLoadingFetchQueries: true,
    isOpenNotAvailableModal: false,
  }
}

const useBookStore = defineStore('book', {
  state: () => ({ ...initState() }),
  actions: {
    setCarPickUpDropOff(values: CarPickUpDropOffTimePreferences) {
      this.carPickUpDropOff = { ...values }
    },
    setActiveStep(step: BookingStepType) {
      this.activeStep = step
    },
    setGlobalIsLoadingQueries(value: boolean) {
      this.isLoadingFetchQueries = value
    },
    setOpenNotAvailableModal(value: boolean) {
      this.isOpenNotAvailableModal = value
    },
  },
})

export default useBookStore
