import { defineStore } from 'pinia'

const useMainStore = defineStore('main', {
  state: () => ({
    // add initial state here
  }),
  actions: {
    // add actions here
  },
})

export default useMainStore
