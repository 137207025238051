<template>
  <JTabs
    v-model="selectedTab"
    as="div"
    :tabItems="tabItems"
    tabListClass="border-b border-line gap-x-24 px-12 md:px-24"
    panelClass="py-12 md:py-16 md:px-20"
    buttonClass="pb-6 border-b-2 border-transparent"
    selectedClass="text-primary whitespace-pre !border-primary"
    modelOptions
    class="-mx-12 overflow-hidden md:-mx-24"
  >
    <template #activityReport> <LoyaltyActivityReports /> </template>

    <template #messageToTeam>
      <MessageLoyaltyProgramTeamForm />
    </template>

    <template #aboutLoyalty>
      <p class="text-subject text-gray-600">
        {{ t('loyalty.aboutLoyaltyDescription') }}
      </p>
    </template>
  </JTabs>
</template>

<script lang="ts" setup>
import { JTabs } from '@journey/ui-kit'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import LoyaltyActivityReports from '../LoyaltyActivityReports/LoyaltyActivityReports.vue'
import MessageLoyaltyProgramTeamForm from '../../Forms/MessageLoyaltyProgramTeamForm/MessageLoyaltyProgramTeamForm.vue'

const { t } = useI18n()

// TAB
const selectedTab = ref<number>()
const tabItems = ref([
  {
    label: t('loyalty.activityReport'),
    value: 'activityReport',
  },
  {
    label: t('loyalty.messageToTheTeam'),
    value: 'messageToTeam',
  },
  {
    label: t('loyalty.aboutLoyalty'),
    value: 'aboutLoyalty',
  },
])
</script>
