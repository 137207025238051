import type { RouteRecordRaw, Router } from 'vue-router'

const INCLUDES_INDEX_PAGE = import.meta.env.VITE_INCLUDES_INDEX_PAGE

const MessagesRoutes: RouteRecordRaw = {
  path: '/messages',
  name: 'MessagesModulePages',
  redirect: { name: 'Messages' },
  children: [
    {
      path: '',
      name: 'Messages',
      component: () => import('./views/MessagesPage.vue'),
      meta: {
        layout: 'Default',
      },
    },
    {
      path: 'show',
      name: 'Message',
      component: () => import('./views/MessagePage.vue'),
      beforeEnter: ({ query }) => {
        if (
          Object.keys(query).length === 0 ||
          !query.messageSource ||
          !query.messageSource.length ||
          !query.messageDatetime ||
          !query.messageDatetime.length
        ) {
          return {
            name: INCLUDES_INDEX_PAGE === 'true' ? 'Index' : 'Search',
          }
        }
      },
      meta: {
        layout: 'Default',
      },
    },
  ],
  meta: {
    layout: 'Default',
    requiresAuth: true,
  },
}

export default (router: Router) => {
  router.addRoute(MessagesRoutes)
}
