<template>
  <JModal
    v-model="internalModelValue"
    size="sm"
    :title="$t('support.contactUs')"
  >
    <ContactSupportForm class="pb-32" @submit="onSubmit" />
  </JModal>

  <ContactSupportConfirmationDialog v-model="confirmationModalModelValue" />
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { JModal } from '@journey/ui-kit'
import { useVModel } from '@vueuse/core'
import ContactSupportForm from '../ContactSupportForm/ContactSupportForm.vue'
import type { Emits, Props } from './ContactSupportModal.types'
import ContactSupportConfirmationDialog from '@/modules/Support/components/ContactSupportConfirmationDialog/ContactSupportConfirmationDialog.vue'

// TOGGLE
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const emit = defineEmits<Emits>()

const internalModelValue = useVModel(props, 'modelValue', emit)

// CONFIRM
const confirmationModalModelValue = ref(false)

function onSubmit() {
  confirmationModalModelValue.value = true
  internalModelValue.value = false
}
</script>
