export interface UserNotificationPreferences {
  notification: {
    isWebActive: boolean
    isMobileActive: boolean
  }
}

export enum PushNotificationAnswerState {
  NOT_ANSWERED,
  CONFIRM,
  LATER,
}
