export default {
  en: {
    currencyFormat: {
      style: 'currency',
      currency: 'USD',
    },
  },
  ar: {
    currencyFormat: {
      style: 'currency',
      currency: 'RUB',
    },
  },
}
