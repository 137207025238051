import { defineStore } from 'pinia'
import type { LoyaltySection } from '@/modules/Loyalty/types'

interface State {
  currentLoyaltySection: Maybe<LoyaltySection>
  previousLoyaltySection: Maybe<LoyaltySection>
  isConfirmModalOpen: boolean
  confirmationPayload: unknown
  activeFormIndex: number
  nextFormIndex: number
  shouldCloseLoyaltyModal: boolean
  isLoyaltyModalOpen: boolean
}

const INITIAL_STATE: State = {
  currentLoyaltySection: null,
  previousLoyaltySection: null,
  isConfirmModalOpen: false,
  confirmationPayload: null,
  activeFormIndex: -1,
  nextFormIndex: -1,
  shouldCloseLoyaltyModal: false,
  isLoyaltyModalOpen: false,
}

const useLoyaltyStore = defineStore('loyalty', {
  state: () => ({ ...INITIAL_STATE }),
  getters: {
    currentLoyaltySectionTitle(state) {
      if (!state.currentLoyaltySection) {
        return ''
      }

      return state.currentLoyaltySection.title
    },
    previousLoyaltySectionTitle(state) {
      if (!state.previousLoyaltySection) {
        return ''
      }

      return state.previousLoyaltySection.title
    },
  },
  actions: {
    setCurrentLoyaltySection(loyaltySection: Maybe<LoyaltySection>) {
      this.currentLoyaltySection = loyaltySection
    },
    setPreviousLoyaltySection(loyaltySection: Maybe<LoyaltySection>) {
      this.previousLoyaltySection = loyaltySection
    },
    clearCurrentLoyaltySection() {
      this.currentLoyaltySection = null
    },
    setIsConfirmModalOpen(isConfirmModalOpen: boolean) {
      this.isConfirmModalOpen = isConfirmModalOpen
    },
    setConfirmationPayload(confirmationPayload: unknown) {
      this.confirmationPayload = confirmationPayload
    },
    setActiveFormIndex(activeFormIndex: number) {
      this.activeFormIndex = activeFormIndex
    },
    setNextLoyaltyForm(nextFormIndex: number) {
      this.nextFormIndex = nextFormIndex
    },
    setShouldCloseLoyaltyModal(shouldCloseLoyaltyModal: boolean) {
      this.shouldCloseLoyaltyModal = shouldCloseLoyaltyModal
    },
    setIsLoyaltyModalOpen(isLoyaltyModalOpen: boolean) {
      this.isLoyaltyModalOpen = isLoyaltyModalOpen
    },
  },
})

export default useLoyaltyStore
