import type { RouteRecordRaw, Router } from 'vue-router'

const TripsRoutes: RouteRecordRaw = {
  path: '/trips',
  name: 'TripsModulePages',
  redirect: { name: 'Trips' },
  children: [
    {
      path: '',
      name: 'Trips',
      component: () => import('./views/TripsPage.vue'),
      meta: {
        layout: 'Default',
      },
    },
    {
      path: ':id',
      name: 'Trip',
      component: () => import('./views/TripPage.vue'),
      meta: {
        layout: 'Default',
      },
    },
  ],
  meta: {
    layout: 'Default',
    requiresAuth: true,
  },
}

export default (router: Router) => {
  router.addRoute(TripsRoutes)
}
