export const REGEX = {
  ONLY_LETTERS: /^[a-zA-Z\s]+$/,
  ONLY_NUMBERS: /^[0-9\s]+$/,
  ONLY_LETTERS_AND_NUMBERS: /^[a-zA-Z0-9]+$/,
  TELEPHONE: /^[0-9]{7,14}$/,
  DATE: /^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).{8,}$/,
  ANY_REGEX_PATTERN: /\(Any\)/gi,
  PASSPORT: /^[A-Za-z0-9]{5,9}$/,
}
