<template>
  <JAccordionGroup
    :modelValue="activeFormIndex"
    :accordions="profileSections"
    @update:model-value="displayConfirmationModalIfTouched($event)"
  />
</template>

<script setup lang="ts">
import { inject, ref, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { JAccordionGroup } from '@journey/ui-kit'
import useHelpStore from '@/modules/Help/store'
import {
  UPDATE_PROFILE_SECTIONS,
  updateProfileSectionSharedStateInjectionKey,
} from '@/modules/Profile/constants'
import useProfileStore from '@/modules/Profile/store'
import { HELP_SECTIONS } from '@/modules/Core/constants'

const { t } = useI18n()

const profileStore = useProfileStore()

// PROFILE SECTIONS
const profileSections = UPDATE_PROFILE_SECTIONS.map(({ content, title }) => ({
  title: t(title),
  content,
  lazy: true,
}))

const { activeFormIndex } = storeToRefs(profileStore)
const previousFormIndex = ref(-1)

const updateProfileSectionSharedState = inject(
  updateProfileSectionSharedStateInjectionKey
)

function anAccordionIsGoingToOpen(value: number) {
  return value !== -1
}

function onlyOpenAccordionIsGoingToClose(value: number) {
  return value === -1
}

function allAccordionsWereClosed(oldValue: number) {
  return oldValue === -1
}

function anAccordionIsOpenAndAnotherAccordionIsGoingToCloseItAndOpenItself(
  value: number,
  oldValue: number
) {
  return value !== -1 && oldValue !== -1
}

function findAccordionThatIsClosing(idx: number) {
  return UPDATE_PROFILE_SECTIONS.find((_section, index) => index === idx)
}

function findAccordionThatIsOpening(idx: number) {
  return UPDATE_PROFILE_SECTIONS.find((_section, index) => index === idx)
}

function isPreviousSectionTouched() {
  return updateProfileSectionSharedState!.items[
    profileStore.previousProfileSectionTitle
  ]?.touched
}

function isCurrentSectionTouched() {
  return updateProfileSectionSharedState!.items[
    profileStore.currentProfileSectionTitle
  ]?.touched
}

async function displayConfirmationModalIfTouched(value: number) {
  if (anAccordionIsGoingToOpen(value)) {
    if (allAccordionsWereClosed(previousFormIndex.value)) {
      profileStore.setActiveFormIndex(value)
    }

    profileStore.setCurrentProfileSection(findAccordionThatIsOpening(value))
  }

  if (onlyOpenAccordionIsGoingToClose(value)) {
    profileStore.setNextProfileForm(-1)

    if (!isCurrentSectionTouched()) {
      profileStore.setActiveFormIndex(value)
      profileStore.setCurrentProfileSection(null)

      return
    }

    profileStore.setIsConfirmModalOpen(true)
  } else if (
    anAccordionIsOpenAndAnotherAccordionIsGoingToCloseItAndOpenItself(
      value,
      previousFormIndex.value
    )
  ) {
    profileStore.setPreviousProfileSection(
      findAccordionThatIsClosing(previousFormIndex.value)
    )

    if (!isPreviousSectionTouched()) {
      profileStore.setActiveFormIndex(value)
      profileStore.setPreviousProfileSection(null)

      return
    }

    profileStore.setNextProfileForm(value)

    profileStore.setIsConfirmModalOpen(true)
  }
}

watchEffect(() => (previousFormIndex.value = activeFormIndex.value))

// RECENT ACTIVITY
const helpStore = useHelpStore()

helpStore.setRecentActivity(HELP_SECTIONS.Profile)
</script>
