import * as Sentry from '@sentry/vue'
import type { App } from 'vue'
import router from '@/router'

export function initSentry(app: App) {
  Sentry.init({
    app,
    environment: import.meta.env.VITE_SENTRY_ENV,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
      }),
    ],

    tracesSampleRate: 1.0,

    tracePropagationTargets: [
      'localhost',
      /^https:\/\/web\.(stagetm|demotm)\.journeymentor\.net\//,
    ],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
