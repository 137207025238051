import type { MaybeRef } from 'vue'
import { computed, isRef, ref, watch } from 'vue'
import { useCurrencyQuery } from '@/modules/Core/requests/queries/useCurrencyQuery'
import type { Currency } from '@/modules/Core/types/model/currency'
import useCurrencyStore from '@/modules/Core/store/currency'

export default function useCurrency() {
  const { data } = useCurrencyQuery()
  const currencyStore = useCurrencyStore()
  const currencyOptions = computed(() => data.value || [])

  function formatPrice(price: number, precision = 2) {
    return price.toFixed(precision).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
  }

  function getCurrencySymbol(currency: string) {
    return (
      currencyOptions.value?.find(
        (option: Currency) =>
          option.title === currency || option.description === currency
      )?.symbol ?? ''
    )
  }

  function formatCurrency(
    price: MaybeRef<Maybe<number>>,
    currency: MaybeRef<Maybe<string>>,
    precision = 2
  ) {
    const priceRef = isRef(price) ? price : ref(price)
    const currencyRef = isRef(currency) ? currency : ref(currency)

    const normalizedPrice = computed(() => priceRef.value ?? 0)
    const normalizedCurrency = computed(() => currencyRef.value ?? '')

    return computed(
      () =>
        `${getCurrencySymbol(normalizedCurrency.value)}${formatPrice(
          normalizedPrice.value,
          precision
        )}`
    )
  }

  watch(currencyOptions, () => {
    if (currencyStore.currency.symbol) {
      return
    }

    const initialCurrency = currencyOptions.value.find(
      (currency) => currency.title === 'USD'
    )

    if (!initialCurrency) {
      return
    }

    currencyStore.setCurrency(initialCurrency)
  })

  return {
    currencyOptions,
    formatCurrency,
  }
}
