import type { InjectionKey } from 'vue'
import { defineAsyncComponent, markRaw } from 'vue'
import type { LoyaltySectionSharedStateProvider } from '@/modules/Loyalty/types'

export const loyaltySectionSharedStateInjectionKey = Symbol(
  'loyaltySectionSharedStateInjectionKey'
) as InjectionKey<{
  items: LoyaltySectionSharedStateProvider
  setTouched: (title: string, to: boolean) => void
}>

export const ACCOUNT_ACTIVITY_REPORT_FORM_INJECTION_KEY =
  'loyalty.accountActivityReport'

export const MESSAGE_LOYALTY_ACCOUNT_TEAM_FORM_INJECTION_KEY =
  'loyalty.messageTheLoyaltyAccountProgramTeam'
export const ABOUT_LOYALTY_ACCOUNT_PROGRAM_FORM_INJECTION_KEY =
  'loyalty.aboutTheLoyaltyAccountProgram'
export const LOYALTY_FORMS = [
  {
    title: 'loyalty.transaction.title',
    form: 'LoyaltyActivityTransactionReport',
    content: markRaw(
      defineAsyncComponent(
        () =>
          import(
            '@/modules/Loyalty/components/LoyaltyModal/LoyaltyActivityReports/LoyaltyActivityTransactionReport/LoyaltyActivityTransactionReport.vue'
          )
      )
    ),
  },
]
