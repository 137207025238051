<template>
  <JModal
    :modelValue="profileStore.isProfileModalOpen"
    :title="$t('profile.updateMyPersonalInformation')"
    @update:model-value="displayConfirmationModalIfTouched"
  >
    <template #navigation>
      <button @click="helpStore.toggleHelpModal(true)">
        <JIcon
          name="question-mark-outline"
          class="text-metadata text-gray-500"
        />
      </button>
    </template>

    <template #description>
      <p class="mb-16">
        {{ $t('profile.updatePersonalInformationDescription') }}
      </p>
    </template>

    <ProfileModalContent />

    <slot />
  </JModal>
</template>

<script setup lang="ts">
import { inject, watch } from 'vue'
import { JIcon, JModal } from '@journey/ui-kit'
import useProfileStore from '@/modules/Profile/store'
import { updateProfileSectionSharedStateInjectionKey } from '@/modules/Profile/constants'
import ProfileModalContent from '@/modules/Profile/components/ProfileModal/ProfileModalContent/ProfileModalContent.vue'
import useHelpStore from '@/modules/Help/store'

// CONFIRMATION DIALOG
const profileStore = useProfileStore()

const updateProfileSectionSharedState = inject(
  updateProfileSectionSharedStateInjectionKey
)

function isCurrentSectionTouched() {
  return updateProfileSectionSharedState!.items[
    profileStore.currentProfileSectionTitle
  ]?.touched
}

function displayConfirmationModalIfTouched(value: boolean) {
  if (value) {
    return
  }

  profileStore.setShouldCloseProfileModal(true)

  if (!isCurrentSectionTouched()) {
    profileStore.setActiveFormIndex(-1)
    profileStore.setCurrentProfileSection(null)
    profileStore.setIsProfileModalOpen(false)
    profileStore.setConfirmationPayload(null)

    return
  }

  profileStore.setIsConfirmModalOpen(true)
}

watch(
  () => profileStore.isProfileModalOpen,
  () => profileStore.setShouldCloseProfileModal(false)
)

const helpStore = useHelpStore()
</script>
