import { type UseMutationOptions, useMutation } from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import type { SearchFlightsDirectSuccessResponse } from '@/modules/Search/types/api/response/search'
import type { SearchFlightPayload } from '@/modules/Search/types/api/payload/search'
import { axiosInstance } from '@/modules/Core/plugins/axios'

export const MUTATION_KEY = 'flights-direct'
export const ENDPOINT = '/Search/GetFlightsDirect'

export function fetchFlightsDirect(payload: SearchFlightPayload) {
  return axiosInstance.post<SearchFlightsDirectSuccessResponse>(
    ENDPOINT,
    payload,
    // TODO: Remove the following baseURL configuration once the API is fully integrated into the backend
    {
      baseURL: import.meta.env.VITE_API_SEARCH_BASE_URL,
    }
  )
}

export function useFetchFlightsDirectMutation(
  options?: UseMutationOptions<
    AxiosResponse<SearchFlightsDirectSuccessResponse>,
    AxiosCustomError,
    SearchFlightPayload,
    any
  >
) {
  return useMutation({
    mutationKey: [MUTATION_KEY],
    mutationFn: fetchFlightsDirect,
    ...options,
  })
}
