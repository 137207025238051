<template>
  <JModal v-model="internalModelValue" size="xs" persistent>
    <div class="py-20">
      <div
        class="mx-auto mt-10 flex h-[8rem] w-[8rem] items-center justify-center rounded-full"
      >
        <CircleCheck />
      </div>

      <h5 class="my-10 text-center text-pane-header font-medium">
        {{ $t('support.sentToEmailMessage', { email: SUPPORT_EMAIL_ADDRESS }) }}
      </h5>

      <div class="w-full">
        <JButton
          block
          color="danger"
          class="rounded-sm"
          @click="internalModelValue = false"
        >
          {{ $t('words.ok') }}
        </JButton>
      </div>
    </div>
  </JModal>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { JButton, JModal } from '@journey/ui-kit'
import type { Emits, Props } from './ContactSupportConfirmationDialog.types'
import { SUPPORT_EMAIL_ADDRESS } from '@/modules/Support/constants'
import CircleCheck from '@/assets/svg/circle-check.svg'

// TOGGLE
const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const emit = defineEmits<Emits>()

const internalModelValue = useVModel(props, 'modelValue', emit)
</script>
