import { defineStore } from 'pinia'
import useProfileStore from '@/modules/Profile/store'
import type { TripForm } from '@/modules/Trips/types'
import type { TripItem } from '@/modules/Trips/types/model/reviewTrips'

interface State {
  form: Maybe<TripForm>
  isOpen: boolean
  openedTrip: Maybe<TripItem>
  actionTripName: Maybe<string>
}

const useReviewTripsStore = defineStore('reviewTrips', {
  state: (): State => ({
    form: undefined,
    isOpen: false,
    openedTrip: null,
    actionTripName: null,
  }),
  actions: {
    open(form: TripForm) {
      this.form = form
      this.isOpen = true
    },
    close() {
      const profileStore = useProfileStore()

      profileStore.setCurrentProfileSection(null)

      this.form = undefined
      this.isOpen = false
    },
    setOpenedTrip(trip: Maybe<TripItem>) {
      this.openedTrip = trip
    },
    setActionTripName(actionTripName: Maybe<string>) {
      this.actionTripName = actionTripName
    },
  },
})

export default useReviewTripsStore
