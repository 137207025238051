import type { RouteRecordRaw, Router } from 'vue-router'

const MainRoutes: RouteRecordRaw = {
  path: '/',
  name: 'MainModulePages',
  redirect: { name: '' },
  children: [
    {
      path: '/notAuthorized',
      name: 'NotAuthorized',
      component: () => import('./views/NotAuthorizedPage.vue'),
      meta: {
        layout: 'Default',
      },
    },
    {
      path: '/server-error',
      name: 'ServerError',
      component: () => import('./views/ServerErrorPage.vue'),
      meta: {
        header: false,
      },
    },
    {
      path: '/terms-and-conditions',
      name: 'TermsAndConditions',
      component: () => import('./views/TermsAndConditionsPage.vue'),
      meta: {
        header: true,
      },
    },
    {
      path: '/about-us',
      name: 'AboutUs',
      component: () => import('./views/AboutUsPage.vue'),
      meta: {
        header: true,
      },
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: () => import('./views/PrivacyPolicy.vue'),
      meta: {
        header: true,
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: () => import('./views/NotFoundPage.vue'),
      meta: {
        layout: 'Error',
      },
    },
  ],
  meta: {
    layout: 'Default',
  },
}

export default (router: Router) => {
  router.addRoute(MainRoutes)
}
