import {
  type Messaging,
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { initializeApp } from 'firebase/app'
import { getPlatform } from '../utils'
import { PushNotificationAnswerState } from '../types/model/userNotificationPreferences'
import useUserPreferencesStore from '@/modules/Core/store/userPreferences'
import useToastStore from '@/modules/Core/store/toast'
import {
  useSubscribeUserMutation,
  useUpdateUserPreferencesMutation,
} from '@/modules/Core/requests/mutations'
import { FIREBASE_CONFIG } from '@/config/fcmTokens'

export default function usePushNotification() {
  const userPreferencesStore = useUserPreferencesStore()
  const isSupportedFCM = ref(false)

  let messaging: Maybe<Messaging> = null

  const fcmNotification = storeToRefs(userPreferencesStore).fcmNotification

  async function initializeFCM() {
    const supported = await isSupported()

    isSupportedFCM.value = supported

    if (!supported) {
      return
    }

    messaging = getMessaging(initializeApp(FIREBASE_CONFIG))

    onMessage(messaging, (payload) => {
      const toastStore = useToastStore()

      toastStore.addToast({
        color: 'success',
        title: `${payload.notification?.title}\n${payload.notification?.body}`,
        duration: 6000,
      })
    })
  }

  initializeFCM()

  const { mutate: subscribeUser, isLoading: isSubscribeUserLoading } =
    useSubscribeUserMutation({
      meta: {
        noSuccessToast: true,
      },
    })

  async function requestNotificationPermission() {
    userPreferencesStore.setOpenFcmNotification(true)

    const channel = new BroadcastChannel('sw-installation')

    try {
      await firebaseGetToken()
    } catch (error) {
      channel.addEventListener('message', async () => {
        await firebaseGetToken()
      })
    }

    async function firebaseGetToken() {
      if (!messaging) {
        return
      }

      const currentToken = await getToken(messaging, {
        vapidKey: import.meta.env.VITE_FIREBASE_VAPID,
      })

      if (!currentToken) {
        return
      }

      userPreferencesStore.setFcmNotification({
        token: currentToken,
        answer: PushNotificationAnswerState.CONFIRM,
        operationComplete: true,
        open: false,
      })

      subscribeUser({
        token: currentToken,
        platform: getPlatform(),
      })
    }
  }

  const notificationPreferences = storeToRefs(userPreferencesStore).notification

  const { mutate } = useUpdateUserPreferencesMutation()

  async function updateWebPushNotifications(to: boolean) {
    mutate({
      preferences: {
        notification: {
          isWebActive: to,
          isMobileActive: notificationPreferences.value.isMobileActive,
        },
      },
    })

    if (!to) {
      return
    }

    if (getPlatform() !== 'Web') {
      return
    }

    if (fcmNotification.value.token) {
      return
    }

    await requestNotificationPermission()
  }

  async function updateMobilePushNotifications(to: boolean) {
    mutate({
      preferences: {
        notification: {
          isWebActive: notificationPreferences.value.isWebActive,
          isMobileActive: to,
        },
      },
    })

    if (!to) {
      return
    }

    if (getPlatform() === 'Web') {
      return
    }

    if (fcmNotification.value.token) {
      return
    }

    await requestNotificationPermission()
  }

  async function resetFcmNotification() {
    userPreferencesStore.resetFcmNotification()
  }

  return {
    isSupportedFCM,
    fcmNotification,
    updateMobilePushNotifications,
    updateWebPushNotifications,
    notificationPreferences,
    isSubscribeUserLoading,
    requestNotificationPermission,
    resetFcmNotification,
  }
}
