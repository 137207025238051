import type { HeaderItem } from './TheHeaderContent.types'

export const HEADER_ITEMS: HeaderItem[] = [
  {
    title: 'loyaltyAccount',
    event: 'loyaltyAccount',
  },
  {
    title: 'reviewMessagesTitle',
    event: 'reviewMessage',
  },
  {
    title: 'reviewTripsTitle',
    event: 'reviewTrips',
  },
  {
    title: 'updateProfile',
    event: 'updateProfile',
  },
  {
    title: 'words.help',
    event: 'help',
    noAuth: true,
  },
]

export const HEADER_OFFSET_SCROLL = 30
