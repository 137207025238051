import { defineStore } from 'pinia'
import type { Telephone } from '@journey/ui-kit'
import type { SignupConfirmSuccessResponse } from '@/modules/Auth/types/api/response/auth'
import type { User } from '@/modules/Auth/types/model/user'
import type {
  AuthChannel,
  AuthenticationFlow,
  AuthenticationStep,
} from '@/modules/Auth/types'

interface UserName {
  first: string
  middle: string
  last: string
}

interface State {
  flow: AuthenticationFlow
  step: AuthenticationStep
  authFormIsOpen: boolean
  authFormIsDisplayed: boolean
  logoutModalIsOpen: boolean
  user: {
    name: UserName
    email: string
    phone: Maybe<Telephone>
    homeAirport?: string
    id: string
  }
  tokens: {
    access: string
    refresh: string
  }
  otpRemainingTime: number
  channel: AuthChannel
}

function initState(): State {
  return {
    flow: 'login' as AuthenticationFlow,
    step: 'guest' as AuthenticationStep,
    authFormIsOpen: false,
    authFormIsDisplayed: false,
    logoutModalIsOpen: false,
    user: {
      name: {
        first: '',
        middle: '',
        last: '',
      },
      email: '',
      phone: null,
      homeAirport: '',
      id: '',
    },
    tokens: {
      access: '',
      refresh: '',
    },
    otpRemainingTime: 0,
    channel: 'email' as AuthChannel,
  }
}

const useAuthStore = defineStore('auth', {
  state: (): State => ({ ...initState() }),
  getters: {
    userFullName: (state) => {
      const name = state.user.name

      return `${name.first} ${name.last}`.trim()
    },
    userFullNameWithMiddleName: (state) => {
      const name = state.user.name

      return `${name.first} ${name.middle} ${name.last}`.trim()
    },
    loggedIn: (state) => state.step === 'loggedIn',
    fullPhoneNumber(state) {
      return `${state.user.phone?.code}${state.user.phone?.number}`
    },
  },
  actions: {
    toggleAuthForm(to?: boolean) {
      if (typeof to === 'undefined') {
        this.authFormIsOpen = !this.authFormIsOpen

        return
      }

      this.authFormIsOpen = to
    },
    toggleLogoutModal(to?: boolean) {
      if (typeof to === 'undefined') {
        this.logoutModalIsOpen = !this.logoutModalIsOpen

        return
      }

      this.logoutModalIsOpen = to
    },
    setUser(user: Partial<User>) {
      const result = {
        name: {
          first: user.firstname ?? this.user.name.first,
          middle: '',
          last: user.lastname ?? this.user.name.last,
        },
        phone: null as Maybe<Telephone>,
        email: user.email ?? this.user.email,
        homeAirport: user.homeAirport,
        id: user.id || '',
      }

      if (user.phone) {
        result.phone = user.phone
      }

      this.user = result
    },
    setStep(set: AuthenticationStep) {
      this.step = set
    },
    setFlow(flow: AuthenticationFlow) {
      this.flow = flow
    },
    setTokens({
      data: { accessToken, refreshToken },
    }: SignupConfirmSuccessResponse) {
      this.tokens = {
        access: accessToken,
        refresh: refreshToken,
      }
    },
    logout() {
      this.$state = initState()
    },
    setChannel(channel: AuthChannel) {
      this.channel = channel
    },
    setOTPRemainingTime(remainingTime: number) {
      this.otpRemainingTime = remainingTime
    },
    setAuthFormIsDisplayed(authFormIsDisplayed: boolean) {
      this.authFormIsDisplayed = authFormIsDisplayed
    },
  },
  persist: {
    storage: window.localStorage,
  },
})

export default useAuthStore
