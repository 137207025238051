import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import type { CreateFriendPayload } from '../../types/api/payload/friend'
import type { FriendSuccessResponse } from '../../types/api/response/friend'
import { FRIENDS_QUERY_KEY } from '../queries'
import { axiosInstance } from '@/modules/Core/plugins/axios'

const ENDPOINT = '/User/Friend'

export function createFriend(friend: CreateFriendPayload) {
  return axiosInstance
    .post<FriendSuccessResponse>(ENDPOINT, friend)
    .then((response) => response)
}

export function useCreateFriendMutation(
  options?: UseMutationOptions<
    AxiosResponse<FriendSuccessResponse>,
    AxiosCustomError,
    CreateFriendPayload,
    any
  >
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: CreateFriendPayload) => createFriend(payload),
    ...options,
    async onSuccess() {
      await queryClient.invalidateQueries([FRIENDS_QUERY_KEY])
    },
  })
}
