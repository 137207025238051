import { ref } from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default function useFormatUTCDate(
  date: Date | string,
  formatStr = 'DD MMMM YYYY HH:mm'
) {
  const formattedDate = ref('')

  const formatUTCDate = () => {
    try {
      formattedDate.value = dayjs.utc(new Date(date)).format(formatStr)
    } catch (error) {
      console.error('Error formatting date:', error)
      formattedDate.value = ''
    }
  }

  formatUTCDate()

  return {
    formattedDate,
    formatUTCDate,
  }
}
