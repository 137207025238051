<template>
  <JModal v-if="isDesktop" v-model="helpStore.isHelpOpen" size="md" noBody>
    <template #header>
      <div class="relative -mb-16 border-b border-line px-24 pb-16">
        <strong class="text-pane-header text-primary">
          {{ $t('words.help') }}
        </strong>

        <button
          class="absolute right-24 top-[calc(50%-8px)] -translate-y-1/2 transform text-gray-500"
          type="button"
          @click="close"
        >
          <JIcon name="close" />
        </button>
      </div>
    </template>

    <div class="px-24">
      <HelpContent />
    </div>
  </JModal>

  <JBottomSheet
    v-else
    v-model="helpStore.isHelpOpen"
    fullScreen
    class="z-30"
    :eager="false"
  >
    <template #header>
      <JContainer class="flex justify-center p-16">
        <TheMobileHeader
          :title="$t('words.help')"
          :showHelpIcon="false"
          titleClass="text-dark font-bold text-base"
          showBack
          :closeHelp="false"
          @back="close"
        />
      </JContainer>
    </template>

    <HelpContent ref="helpContentRef" />
  </JBottomSheet>
</template>

<script lang="ts" setup>
import { JBottomSheet, JContainer, JIcon, JModal } from '@journey/ui-kit'
import { ref } from 'vue'
import { useDevicePlatform } from '@/modules/Core/composables'
import HelpContent from '@/modules/Help/components/HelpContent/HelpContent.vue'
import TheMobileHeader from '@/modules/Core/components/layout/MobileHeader/TheMobileHeader.vue'
import useHelpStore from '@/modules/Help/store'

const { isDesktop } = useDevicePlatform()
const helpStore = useHelpStore()

const helpContentRef = ref<InstanceType<typeof HelpContent> | null>(null)

function close() {
  if (helpContentRef.value?.selectedHelpItem.length) {
    helpContentRef.value?.clearSelectedHelpItem()
    return
  }

  helpStore.toggleHelpModal(false)
}
</script>
