import type { RouteRecordRaw, Router } from 'vue-router'

const PaymentRoutes: RouteRecordRaw = {
  path: '/payment',
  name: 'PaymentModulePages',
  redirect: { name: '' },
  children: [
    {
      path: 'verify',
      name: 'PaymentResult',
      component: () => import('./views/PaymentResultPage.vue'),
      meta: {
        layout: 'Default',
      },
      // beforeEnter(to, _, next) {
      //   const { query } = to
      //   if (query.paymentId && query.token && query.PayerID && query.trackId) {
      //     next()
      //   } else {
      //     next({ name: 'NotFound', replace: true })
      //   }
      // },
    },
  ],
  meta: {
    layout: 'Default',
  },
}

export default (router: Router) => {
  router.addRoute(PaymentRoutes)
}
