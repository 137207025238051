<template>
  <JMenu
    :modelValue="currencyStore.currency"
    :options="currencyOptions"
    :title="`${$t('selectCurrency')}`"
  >
    <template #menuButton>
      <span class="font-semi-bold" @click="setRecentActivityToCurrency">
        {{ currencyStore.currency?.title }}
      </span>
    </template>

    <template #listItem="{ menuItem }">
      <JMenuItem
        :option="menuItem"
        :selectedItem="menuItem.symbol === selectedCurrency.symbol"
        :title="menuItem.description"
        @click=";(selectedCurrency = menuItem as Currency), onChangeCurrency()"
      >
        <template #prependItem>
          <span class="font-bold">
            {{ menuItem.title }}
          </span>
          <span> - </span>
        </template>
        <template #appendItem>
          <JIcon
            v-show="menuItem.symbol === selectedCurrency.symbol"
            name="check-circle-bold-2"
            class="text-primary"
          />
        </template>
      </JMenuItem>
    </template>
  </JMenu>
  <ChangeCurrencyConfirmationDialog
    v-model="isCurrencyInformationDialogOpen"
    @confirm="onConfirmChangeCurrency"
  />
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { JIcon, JMenu, JMenuItem } from '@journey/ui-kit'
import { useCurrency } from '@/modules/Core/composables'
import useCurrencyStore from '@/modules/Core/store/currency'
import useHelpStore from '@/modules/Help/store'
import ChangeCurrencyConfirmationDialog from '@/modules/Core/components/layout/Header/HeaderContent/ChangeCurrencyConfirmationDialog/ChangeCurrencyConfirmationDialog.vue'
import type { Currency } from '@/modules/Core/types/model/currency'
import useItinerarySummary from '@/modules/Search/store/itinerarySummary'
import { HELP_SECTIONS } from '@/modules/Core/constants'

const { currencyOptions } = useCurrency()
const currencyStore = useCurrencyStore()
const itinerarySummary = useItinerarySummary()

const router = useRouter()
const isCurrencyInformationDialogOpen = ref(false)
const selectedCurrency = ref(currencyStore.currency)

// RECENT ACTIVITY
const helpStore = useHelpStore()

function onChangeCurrency() {
  if (!itinerarySummary.itineraryLength) {
    currencyStore.setCurrency(selectedCurrency.value)
    return
  }

  isCurrencyInformationDialogOpen.value = true
}

function onConfirmChangeCurrency() {
  currencyStore.setCurrency(selectedCurrency.value)
  if (router.currentRoute.value.path !== '/') {
    router.push('/')
  }
}

function setRecentActivityToCurrency() {
  helpStore.setRecentActivity(HELP_SECTIONS.LanguageOrCurrency)
}
</script>
