<template>
  <JCollapse :show="!isOnline" class="sticky top-0 z-50 bg-white">
    <div class="flex items-center justify-center bg-warning/20 p-10">
      <JIcon name="wifi-off" size="20" />
      <p class="ml-10 font-medium">{{ $t('noNetwork') }}</p>
    </div>
  </JCollapse>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import { JCollapse, JIcon } from '@journey/ui-kit'

const isOnline = ref(navigator.onLine)

function updateOnlineStatus() {
  isOnline.value = navigator.onLine
}

onMounted(() => {
  window.addEventListener('online', updateOnlineStatus)
  window.addEventListener('offline', updateOnlineStatus)
})

onUnmounted(() => {
  window.removeEventListener('online', updateOnlineStatus)
  window.removeEventListener('offline', updateOnlineStatus)
})
</script>
