<template>
  <div>
    <JAccordionGroup v-model="activeHelpSection" :accordions="accordions" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { JAccordionGroup } from '@journey/ui-kit'
import type { Props } from './FilteredHelpItems.types'

import { generateAccordions } from '@/modules/Help/utils'

const props = defineProps<Props>()

const activeHelpSection = ref(-1)

const accordions = computed(() =>
  generateAccordions([[props.title, props.items]])
)
</script>
