import { createRouter, createWebHistory } from 'vue-router'
import useAuthStore from '@/modules/Auth/store'

export const router = createRouter({
  history: createWebHistory(),
  routes: [],
  scrollBehavior() {
    return { top: 0 }
  },
})
router.beforeEach(async (to, from) => {
  const authStore = useAuthStore()
  const isLoggedIn = authStore.loggedIn

  if (to.meta.requiresAuth && !isLoggedIn) {
    authStore.toggleAuthForm(true)
    await router.replace({ name: from.name as string })
  }
})

export default router
