import type { RouteRecordRaw, Router } from 'vue-router'

const MoreRoutes: RouteRecordRaw = {
  path: '/more',
  name: 'MoreModulePages',
  redirect: { name: 'More' },
  children: [
    {
      path: '',
      name: 'More',
      component: () => import('./views/MorePage.vue'),
    },
  ],
  meta: {
    layout: 'Default',
  },
}

export default (router: Router) => {
  router.addRoute(MoreRoutes)
}
