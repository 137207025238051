import type { Ref } from 'vue'
import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import { LOYALTY_ACCOUNT_QUERY_KEY } from '../queries'
import type { UpdateLoyaltyAccountPayload } from '@/modules/Profile/types/api/payload/loyaltyAccount'
import type { UpdateLoyaltyAccountSuccessResponse } from '@/modules/Profile/types/api/response/loyaltyAccount'
import { axiosInstance } from '@/modules/Core/plugins/axios'

const ENDPOINT = '/LoyaltyAccount'

export function updateLoyaltyAccount(payload: UpdateLoyaltyAccountPayload) {
  return axiosInstance.put<UpdateLoyaltyAccountSuccessResponse>(
    ENDPOINT,
    payload
  )
}

export function useUpdateLoyaltyAccountMutation(
  type: Ref<string>,
  options?: UseMutationOptions<
    AxiosResponse<UpdateLoyaltyAccountSuccessResponse>,
    AxiosCustomError,
    UpdateLoyaltyAccountPayload,
    any
  >
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateLoyaltyAccount,
    async onSuccess() {
      await queryClient.invalidateQueries([LOYALTY_ACCOUNT_QUERY_KEY, type])
    },
    ...options,
  })
}
