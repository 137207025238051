import { storeToRefs } from 'pinia'
import { computed, provide, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import useTripsStore from '@/modules/Trips/store'
import useAuthStore from '@/modules/Auth/store'
import {
  useCreateFamilyMemberMutation,
  useCreateFriendMutation,
  useCreateLoyaltyAccountMutation,
  useCreateMySupplierMutation,
  useUpdateFamilyMemberMutation,
  useUpdateFriendMutation,
  useUpdateLoyaltyAccountMutation,
  useUpdateMySupplierMutation,
  useUpdateProfileMutation,
  useUpdateSearchWeightsMutation,
  useUpdateTravelPreferencesMutation,
  useUpdateUserInterestsAndActivitiesMutation,
} from '@/modules/Profile/requests/mutations'
import type { UpdateProfileSectionSharedStateProvider } from '@/modules/Profile/types'
import type {
  CreateFamilyMemberPayload,
  UpdateFamilyMemberPayload,
} from '@/modules/Profile/types/api/payload/familyMember'
import type { UserInterestsAndActivitiesPayload } from '@/modules/Profile/types/api/payload/interestsAndActivities'
import type {
  CreateLoyaltyAccountPayload,
  UpdateLoyaltyAccountPayload,
} from '@/modules/Profile/types/api/payload/loyaltyAccount'
import type { PrivateSupplierPayload } from '@/modules/Profile/types/api/payload/privateSupplier'
import type { UpdateProfilePayload } from '@/modules/Profile/types/api/payload/profile'
import type { UpdateSearchWeightPayload } from '@/modules/Profile/types/api/payload/searchWeights'
import type { UpdateTravelPreferencesPayload } from '@/modules/Profile/types/api/payload/travelPreferences'
import { useDevicePlatform } from '@/modules/Core/composables'
import {
  MY_FAMILY_FORM_INJECTION_KEY,
  MY_FRIENDS_FORM_INJECTION_KEY,
  MY_INTERESTS_AND_ACTIVITIES_FORM_INJECTION_KEY,
  MY_LOYALTY_ACCOUNTS_FORM_INJECTION_KEY,
  MY_PROFILE_FORM_INJECTION_KEY,
  MY_SEARCH_WEIGHTS_FORM_INJECTION_KEY,
  MY_TRAVEL_PREFERENCES_FORM_INJECTION_KEY,
  RATE_MY_SUPPLIERS_FORM_INJECTION_KEY,
  UPDATE_PROFILE_SECTIONS,
  updateProfileSectionSharedStateInjectionKey,
} from '@/modules/Profile/constants'
import useProfileStore from '@/modules/Profile/store'

function useProfileConfirmationModal() {
  const profileStore = useProfileStore()

  const { isDesktop } = useDevicePlatform()

  const authStore = useAuthStore()
  const currentActiveSupplierType = computed(
    () => profileStore.activeSupplierType
  )

  const { isConfirmModalOpen } = storeToRefs(profileStore)

  const tripsStore = useTripsStore()

  const { t } = useI18n()

  const {
    mutate: updateTravelPreferencesMutate,
    isLoading: updateTravelPreferencesIsLoading,
  } = useUpdateTravelPreferencesMutation()

  const {
    mutate: updateUserInterestsAndActivitiesMutate,
    isLoading: updateInterestsAndActivitiesIsLoading,
  } = useUpdateUserInterestsAndActivitiesMutation()

  const {
    mutateAsync: createFriendMutateAsync,
    isLoading: createFriendIsLoading,
  } = useCreateFriendMutation()

  const {
    mutateAsync: updateFriendMutateAsync,
    isLoading: updateFriendIsLoading,
  } = useUpdateFriendMutation()

  const {
    mutateAsync: createFamilyMemberMutateAsync,
    isLoading: createFamilyMemberIsLoading,
  } = useCreateFamilyMemberMutation()

  const {
    mutateAsync: updateFamilyMemberMutateAsync,
    isLoading: updateFamilyMemberIsLoading,
  } = useUpdateFamilyMemberMutation()

  const {
    mutateAsync: updateProfileMutateAsync,
    isLoading: updateProfileIsLoading,
  } = useUpdateProfileMutation()

  const {
    mutateAsync: createPrivateSupplierMutateAsync,
    isLoading: createPrivateSupplierIsLoading,
  } = useCreateMySupplierMutation(currentActiveSupplierType)

  const {
    mutateAsync: updatePrivateSupplierMutateAsync,
    isLoading: updatePrivateSupplierIsLoading,
  } = useUpdateMySupplierMutation(currentActiveSupplierType)

  const {
    mutateAsync: updateSearchWeightsMutateAsync,
    isLoading: updateSearchWeightsIsLoading,
  } = useUpdateSearchWeightsMutation()

  const {
    mutateAsync: createLoyaltyAccountMutation,
    isLoading: createLoyaltyAccountIsLoading,
  } = useCreateLoyaltyAccountMutation(currentActiveSupplierType)

  const {
    mutateAsync: updateLoyaltyAccountMutation,
    isLoading: updateLoyaltyAccountIsLoading,
  } = useUpdateLoyaltyAccountMutation(currentActiveSupplierType)

  const updateProfileSectionSharedState =
    ref<UpdateProfileSectionSharedStateProvider>({
      [MY_PROFILE_FORM_INJECTION_KEY]: {
        touched: false,
        isLoading: updateProfileIsLoading,
        submit: async (payload: UpdateProfilePayload) => {
          const { data } = await updateProfileMutateAsync(payload)
          const user = {
            name: {
              first: data.data.firstName,
              middle: data.data.middleName,
              last: data.data.lastName,
            },
            email: data.data.email,
            phone: data.data.phone
              ? {
                  code: data.data.phone.code,
                  number: data.data.phone.number,
                }
              : null,
            homeAirport: data.data.homeAirport,
            id: data.data.userId,
          }
          authStore.setUser(user)
        },
      },
      [MY_FAMILY_FORM_INJECTION_KEY]: {
        touched: false,
        isLoading: computed(
          () =>
            createFamilyMemberIsLoading.value ||
            updateFamilyMemberIsLoading.value
        ),
        submit: async (
          payload: CreateFamilyMemberPayload | UpdateFamilyMemberPayload
        ) => {
          if ('familyNumber' in payload) {
            await updateFamilyMemberMutateAsync(payload)
          } else {
            await createFamilyMemberMutateAsync(payload)
          }
        },
      },
      [MY_FRIENDS_FORM_INJECTION_KEY]: {
        touched: false,
        isLoading: computed(
          () => createFriendIsLoading.value || updateFriendIsLoading.value
        ),
        submit: async (payload) => {
          if (payload.previousFirstName) {
            await updateFriendMutateAsync(payload)
          } else {
            await createFriendMutateAsync(payload)
          }
        },
      },
      [MY_LOYALTY_ACCOUNTS_FORM_INJECTION_KEY]: {
        touched: false,
        isLoading: computed(
          () =>
            createLoyaltyAccountIsLoading.value ||
            updateLoyaltyAccountIsLoading.value
        ),
        submit: async (
          payload: (
            | CreateLoyaltyAccountPayload
            | UpdateLoyaltyAccountPayload
          ) & {
            isEdit: boolean
          }
        ) => {
          if (payload.isEdit) {
            await updateLoyaltyAccountMutation(payload)
          } else {
            await createLoyaltyAccountMutation(payload)
          }
        },
      },
      [MY_TRAVEL_PREFERENCES_FORM_INJECTION_KEY]: {
        touched: false,
        isLoading: updateTravelPreferencesIsLoading,
        submit: (payload: UpdateTravelPreferencesPayload) => {
          updateTravelPreferencesMutate(payload)
        },
      },
      [MY_INTERESTS_AND_ACTIVITIES_FORM_INJECTION_KEY]: {
        touched: false,
        isLoading: updateInterestsAndActivitiesIsLoading,
        submit: (payload: UserInterestsAndActivitiesPayload) => {
          updateUserInterestsAndActivitiesMutate(payload)
        },
      },
      [RATE_MY_SUPPLIERS_FORM_INJECTION_KEY]: {
        touched: false,
        isLoading: computed(
          () =>
            createPrivateSupplierIsLoading.value ||
            updatePrivateSupplierIsLoading.value
        ),
        submit: async (
          payload: PrivateSupplierPayload & { isEdit: boolean }
        ) => {
          if (payload.isEdit) {
            await updatePrivateSupplierMutateAsync(payload)
          } else {
            await createPrivateSupplierMutateAsync(payload)
          }
        },
      },
      [MY_SEARCH_WEIGHTS_FORM_INJECTION_KEY]: {
        touched: false,
        isLoading: updateSearchWeightsIsLoading,
        submit: async (payload: UpdateSearchWeightPayload) => {
          await updateSearchWeightsMutateAsync(payload)
        },
      },
    })

  const confirmationModalTitle = computed(() => {
    if (
      !(
        profileStore.previousProfileSection ||
        profileStore.currentProfileSection
      )
    ) {
      return ''
    }

    if (
      !(
        profileStore.previousProfileSectionTitle ||
        profileStore.currentProfileSectionTitle
      )
    ) {
      return ''
    }

    return t('profile.wouldYouLikeToSaveYourChangesToForm', {
      form: t(
        profileStore.previousProfileSectionTitle ||
          profileStore.currentProfileSectionTitle
      ),
    })
  })

  provide(updateProfileSectionSharedStateInjectionKey, {
    items: updateProfileSectionSharedState.value,
    setTouched: (title: string, to: boolean) => {
      updateProfileSectionSharedState.value[title].touched = to
    },
  })

  function confirm() {
    if (profileStore.nextFormIndex === -1) {
      updateProfileSectionSharedState.value[
        profileStore.currentProfileSectionTitle
      ].submit(profileStore.confirmationPayload)
    } else {
      updateProfileSectionSharedState.value[
        profileStore.previousProfileSectionTitle
      ].submit(profileStore.confirmationPayload)
    }

    updateProfileSectionSharedState.value[
      profileStore.currentProfileSectionTitle
    ].touched = false

    profileStore.setConfirmationPayload(null)

    cancel()
  }

  function close(eitherActionIsClicked: boolean) {
    profileStore.setIsConfirmModalOpen(false)

    if (!isDesktop.value) {
      return
    }

    if (profileStore.nextFormIndex === -1 && !tripsStore.isOpen) {
      return
    }

    if (eitherActionIsClicked) {
      profileStore.setPreviousProfileSection(null)
      tripsStore.close()
    } else {
      profileStore.setCurrentProfileSection(profileStore.previousProfileSection)
    }

    profileStore.setPreviousProfileSection(null)
    profileStore.setNextProfileForm(-1)
  }

  function findAccordionThatIsOpening(idx: number) {
    return UPDATE_PROFILE_SECTIONS.find((_section, index) => index === idx)
  }

  function cancel() {
    profileStore.setConfirmationPayload(null)

    if (profileStore.currentProfileSectionTitle) {
      updateProfileSectionSharedState.value[
        profileStore.currentProfileSectionTitle
      ].touched = false
    }

    if (profileStore.previousProfileSectionTitle) {
      updateProfileSectionSharedState.value[
        profileStore.previousProfileSectionTitle
      ].touched = false
    }

    if (!isDesktop.value) {
      if (profileStore.isMobileSubFormOpen) {
        profileStore.setIsMobileSubFormOpen(false)
      } else {
        profileStore.setCurrentProfileSection(null)
      }

      close(true)

      return
    }

    if (profileStore.shouldCloseProfileModal) {
      profileStore.setIsProfileModalOpen(false)
      profileStore.setShouldCloseProfileModal(false)
      profileStore.setActiveFormIndex(-1)
      profileStore.setCurrentProfileSection(null)
    } else {
      profileStore.setActiveFormIndex(profileStore.nextFormIndex)
      profileStore.setCurrentProfileSection(
        findAccordionThatIsOpening(profileStore.nextFormIndex)
      )
    }

    close(true)
  }

  return {
    confirm,
    cancel,
    close,
    isConfirmModalOpen,
    confirmationModalTitle,
  }
}

export default useProfileConfirmationModal
