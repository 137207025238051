import { defineStore } from 'pinia'
import type {
  SearchPanelCar,
  SearchPanelCarOptions,
} from '@/modules/Search/types'
import { CarType } from '@/modules/Search/types'

function initState(): { options: SearchPanelCarOptions } {
  return {
    options: {
      type: CarType.Any,
      carPreferences: [{ dropOffDate: undefined, pickUpDate: undefined }],
    },
  }
}

const useCarSearchOptions = defineStore('carSearchOptions', {
  state: () => ({ ...initState() }),
  actions: {
    setOptions(payload: Partial<SearchPanelCarOptions>) {
      this.options = {
        ...this.options,
        ...payload,
      }
    },
    setCarPreferences(preferences: Array<SearchPanelCar>) {
      this.options.carPreferences = preferences
    },
    resetOptions() {
      this.options = initState().options
    },
  },
})

export default useCarSearchOptions
