import type { App } from 'vue'
import { vueSanitizeDirective } from './vue-sanitize-directive'
import { registerLayouts } from './registerLayouts'
import { vueQuery } from './vueQuery'
import { head } from './head'
import { initSentry } from './sentry'
import i18n from '@/modules/Core/plugins/i18n'
import router from '@/router'
import pinia from '@/store'
import { setupGtag } from '@/modules/Core/plugins/vue-gtag'

export function registerPlugins(app: App) {
  initSentry(app)
  app.use(pinia).use(i18n).use(router).use(vueQuery).use(head)
  vueSanitizeDirective(app)
  registerLayouts(app)
  setupGtag(app, router)
}
