import { type UseMutationOptions, useMutation } from '@tanstack/vue-query'
import type { AxiosResponse } from 'axios'
import type { UpdateUserPreferencesSuccessResponse } from '@/modules/Core/types/api/response/userPreferences'
import type { UserPreferencesPayload } from '@/modules/Core/types/api/payload/userPreferences'
import { axiosInstance } from '@/modules/Core/plugins/axios'

const ENDPOINT = '/User/Preference'

export function updateUserPreferences(payload: UserPreferencesPayload) {
  return axiosInstance.post<UpdateUserPreferencesSuccessResponse>(
    ENDPOINT,
    payload
  )
}

export function useUpdateUserPreferencesMutation(
  options?: UseMutationOptions<
    AxiosResponse<UpdateUserPreferencesSuccessResponse>,
    AxiosCustomError,
    UserPreferencesPayload,
    any
  >
) {
  return useMutation({
    mutationFn: updateUserPreferences,
    ...options,
  })
}
