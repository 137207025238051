import { type UseQueryOptions, useQuery } from '@tanstack/vue-query'
import type { HelpSuccessResponse } from '@/modules/Help/types/api/response/help'
import { axiosInstance } from '@/modules/Core/plugins/axios'

const QUERY_KEY = 'help'
const ENDPOINT = '/Help'

export function fetchHelp() {
  return axiosInstance
    .get<HelpSuccessResponse>(ENDPOINT)
    .then((response) => response.data)
}

export function useHelpQuery(
  options?: UseQueryOptions<Awaited<ReturnType<typeof fetchHelp>>>
) {
  return useQuery({
    queryFn: fetchHelp,
    queryKey: [QUERY_KEY],
    ...options,
  })
}
