import type { InjectionKey } from 'vue'
import { defineAsyncComponent, markRaw } from 'vue'
import type { UpdateProfileSectionSharedStateProvider } from '@/modules/Profile/types'

export const updateProfileSectionSharedStateInjectionKey = Symbol(
  'updateProfileSectionSharedStateInjectionKey'
) as InjectionKey<{
  items: UpdateProfileSectionSharedStateProvider
  setTouched: (title: string, to: boolean) => void
}>

export const MY_SEARCH_WEIGHTS_FORM_INJECTION_KEY = 'profile.mySearchWeights'

export const MY_INTERESTS_AND_ACTIVITIES_FORM_INJECTION_KEY =
  'profile.myInterestsAndActivities'
export const MY_PROFILE_FORM_INJECTION_KEY = 'profile.myProfile'
export const RATE_MY_SUPPLIERS_FORM_INJECTION_KEY = 'profile.rateMySuppliers'
export const MY_TRAVEL_PREFERENCES_FORM_INJECTION_KEY =
  'profile.myTravelPreferences'
export const MY_LOYALTY_ACCOUNTS_FORM_INJECTION_KEY =
  'profile.myLoyaltyAccounts'
export const MY_FRIENDS_FORM_INJECTION_KEY = 'profile.myFriendsProfile'
export const MY_FAMILY_FORM_INJECTION_KEY = 'profile.myFamilyProfile'
export const UPDATE_PROFILE_SECTIONS = [
  {
    title: MY_PROFILE_FORM_INJECTION_KEY,
    form: 'MyProfileForm',
    content: markRaw(
      defineAsyncComponent(
        () =>
          import(
            '@/modules/Profile/components/Forms/MyProfileForm/MyProfileForm.vue'
          )
      )
    ),
  },
  {
    title: MY_FAMILY_FORM_INJECTION_KEY,
    form: 'MyFamilyProfileForm',
    content: markRaw(
      defineAsyncComponent(
        () =>
          import(
            '@/modules/Profile/components/Forms/MyFamilyProfileForm/MyFamilyProfileForm.vue'
          )
      )
    ),
  },
  {
    title: MY_FRIENDS_FORM_INJECTION_KEY,
    form: 'MyFriendsProfileForm',
    content: markRaw(
      defineAsyncComponent(
        () =>
          import(
            '@/modules/Profile/components/Forms/MyFriendsProfileForm/MyFriendsProfileForm.vue'
          )
      )
    ),
  },
  {
    title: MY_LOYALTY_ACCOUNTS_FORM_INJECTION_KEY,
    form: 'MyLoyaltyAccountsForm',
    content: markRaw(
      defineAsyncComponent(
        () =>
          import(
            '@/modules/Profile/components/Forms/MyLoyaltyAccountsForm/MyLoyaltyAccountsForm.vue'
          )
      )
    ),
  },
  {
    title: MY_TRAVEL_PREFERENCES_FORM_INJECTION_KEY,
    form: 'MyTravelPreferencesForm',
    content: markRaw(
      defineAsyncComponent(
        () =>
          import(
            '@/modules/Profile/components/Forms/MyTravelPreferencesForm/MyTravelPreferencesForm.vue'
          )
      )
    ),
  },
  {
    title: MY_INTERESTS_AND_ACTIVITIES_FORM_INJECTION_KEY,
    form: 'MyInterestsAndActivitiesForm',
    content: markRaw(
      defineAsyncComponent(
        () =>
          import(
            '@/modules/Profile/components/Forms/MyInterestsAndActivitiesForm/MyInterestsAndActivitiesForm.vue'
          )
      )
    ),
  },
  {
    title: RATE_MY_SUPPLIERS_FORM_INJECTION_KEY,
    form: 'RateMySuppliersForm',
    content: markRaw(
      defineAsyncComponent(
        () =>
          import(
            '@/modules/Profile/components/Forms/RateMySuppliersForm/RateMySuppliersForm.vue'
          )
      )
    ),
  },
  {
    title: MY_SEARCH_WEIGHTS_FORM_INJECTION_KEY,
    form: 'MySearchWeightsForm',
    content: markRaw(
      defineAsyncComponent(
        () =>
          import(
            '@/modules/Profile/components/Forms/MySearchWeightsForm/MySearchWeightsForm.vue'
          )
      )
    ),
  },
]

export const MINIMUM_NUMBER_OF_SEARCH_RESULTS = 3
export const MAXIMUM_NUMBER_OF_SEARCH_RESULTS = 100

export const FIRST_PAGE = 1
export const INITIAL_PAGE_SIZE = 5
